<template>
	<div class="pt-3 pl-5 pr-5">
		<div class="mb-2">
			<breadCrumb :routesArray="routesArray"></breadCrumb>
		</div>
		<div >
			<h1 class="font-bold">Course Details</h1>
		</div>
		<div>
			<div v-if="dotsLoading" class="mt-2">
				<div class="flex items-center justify-center w-full h-full skeleton mt-4 tableCornerRadius bg-white">
					<dots-loader />
				</div>
			</div>	
		<div class="card mt-5 rounded responsiveCard"   v-else>
			<div class="w-2/5 h-full" :class="{'bg-primary flex justify-center items-center':!courseDetails.courseImageUrl}">
			
				<!-- <h1 class="text-white text-lg">{{courseDetails.courseName}}</h1> -->
				<div v-if="courseDetails.courseImageUrl" class="h-full" >
					<img  :src="courseDetails.courseImageUrl" class="card-bordered bg-cover bg-no-repeat bg-center h-full imageResponsive" />
					<h1 class="text-white text-lg">{{courseDetails.courseName}}</h1>
				</div>
				<div v-else class="bg-primary flex justify-center items-center p-1">
					<h1 class="text-white text-lg">{{courseDetails.courseName}}</h1>
				</div>
			</div>
			<div class="card-body courseInfo">
				<div class="details h-72">
					<div class="flex flex-row">
						<h1 class="font-opensans">{{ courseDetails.courseName }}</h1>
					</div>
					<div class="text-grey mt-2">
						<p class="description font-opensans non-italic font-normal text-base text-black text-justify">{{ courseDetails.courseDescription }}</p>
					</div>
				</div>
				<div class="flex justify-between mt-8" v-if="series[0] != 100">
					<div class="rounded-full btnpills font-opensans text-xs font-bold  bg-primary px-2 text-white">
						Start Date: <span>{{ startedAt }} </span>
					</div>
					<div class="rounded-full btnpills font-opensans text-xs font-bold bg-error px-2 text-white">
						Due Date: <span>{{ userActivity.checkdueDate }} </span>
					</div>
				</div>
				<div class="flex justify-between mt-8" v-else>
					<div class="rounded-full btnpills font-opensans text-xs font-bold  bg-green px-2 text-white">
						<span>Completed</span>
					</div>
					<div class="rounded-full btnpills font-opensans text-xs font-bold bg-gray px-2 text-white">
						<span>{{ completedBy }} </span>
					</div>
					<!-- <button class="rounded-full btnpills font-opensans text-xs font-bold bg-green text-white px-2">Completed</button> -->
					<!-- <p class="rounded-full btnpills bg-gray font-opensans text-xs font-bold text-white px-2">{{ completedBy }}</p> -->
				</div>
			</div>
			<div class="courseStatus">
				<div class="h-full w-full p-2">
					<div class="flex items-center justify-center chart relative">
						<apexchart width="200" height="200" type="radialBar" :options="chartOptions" :series="series"></apexchart>
					</div>
						<div class="flex items-center justify-center flex-col">
							<div class="btnprimary" @click="startCourse()" v-if="series[0] == 0 && startedAt == ''">Start</div>
							<div class="btnprimary" @click="startCourse()" v-else-if="series[0] == 100">Retake</div>
						<div class="btnprimary cursor-pointer" @click="startCourse()" v-else>Resume</div>
					
					<div>			
					<div class="btndisabled mt-3" v-if="series[0] != 100">Certificate</div>
					<div class="btnprimary mt-3"  @click="downloadCerificate(userInfo)" v-else>
						<a >Certificate</a>
					</div>
					</div>
							</div>
				</div>
			</div>
		</div>
		</div>
			<!-- <div class="flex justify-center items-center" v-else>
				<p>No Active Screens</p>
				</div> -->
		<div>
			<table v-if="modules.length>0" class="tableCornerRadius rounded">
				<thead>
					<tr>
						<th class="header cursor-pointer flex items-center">
							Modules
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="module in modules" :key="module">
						<td>{{ module.moduleName }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<style scoped>
.h-80{
	height: 380px;
}

table{
	margin-top: 20px;
}
p{
	font-size: 14px !important;
	line-height: 20px;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { toast, emitter } from '../../main';
import VueApexCharts from 'vue3-apexcharts';
import breadCrumb from '@/components/breadCrumb.vue';
import dotsLoader from '@/components/dotsLoader.vue';
export default defineComponent({
	components: { apexchart: VueApexCharts,breadCrumb,dotsLoader },
	data(): any {
		return {
			courseDetails: {},
			modules: [],
			routesArray:[],
			userActivity: {},
			completedBy: '',
			closedroute: {},
			new_window: {},
			startedAt: '',
			seriesProgressData: [],
			chartOptions: {
				type: 'radialBar',
				colors: ['#152a43'],
				labels: ['Course'],

				plotOptions: {
					radialBar: {
						// dataLabels: {
						// 	show: false
						// },

						dataLabels: {
							name: {
								show: false

								// fontSize: '17px'
							},
							value: {
								fontSize: '35px',
								show: true
							}
						},

						hollow: {
							margin: 15,
							size: '64%'
							// background: '#152a43'
						}
						// track:{
						// 	background: '#152a43'
						// }
					}
				}
			},

			series: []
		};
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' ,dotsLoading:'dotsLoading'})
	},
	// components :{
	// 	breadCrumb,

	// },
	methods: {
		Images(payload: any) {
			console.log(payload.courseImageUrl);
			if (payload.courseImageUrl != '' && payload.courseImageUrl != undefined) {
				return payload.courseImageUrl;
			} else {
				return process.env.VUE_APP_COMMON_IMAGE;
			}
		},

		async getAllModules() {
			console.log(this.$route.params.id);
			await this.$http
				.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/modules/getAll`)
				.then(async (res: any) => {
					// this.spinnerLoading = false;
					this.modules = res.data;
					this.modules = this.modules.filter((module: any) =>{ return module.screens && module.isModuleActive == true});
					await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/user/${this.userInfo._id}/course/${this.$route.params.courseId}/userActivity`).then((a: any) => {
						this.userActivity = a.data[0];

						// if(this.userActivity.examStatus){
						// 	let date = new Date(this.userActivity.examStatus.startedAt);
						// 	this.completedBy = (`0`+(date.getDate() )).slice(-2)+ '-' + (`0`+(date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();
						// }
						// else if(this.userActivity.modules) {
						// 		let module = this.userActivity.modules.length - 1;
						// 	let date = new Date(this.userActivity.modules[module].updatedAt);
						// 	this.completedBy = (`0`+(date.getDate() )).slice(-2)+ '-' + (`0`+(date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();
						// }
						 if (this.userActivity.updatedAt) {
						// if (this.userActivity.totalModules == this.userActivity.completedModules) {
							let date = new Date(this.userActivity.updatedAt);
							this.completedBy = (`0`+(date.getDate() )).slice(-2) + '-' + (`0`+(date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();
					
						}
						else{
							this.completedBy =''
						}

						if(this.userActivity.examStatus){
							let date = new Date(this.userActivity.examStatus.startedAt);
							this.startedAt = (`0`+(date.getDate() )).slice(-2)+ '-' + (`0`+(date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();
						}
						else if (this.userActivity.modules) {
							let date = new Date(this.userActivity.modules[0].updatedAt);
							this.startedAt = (`0`+(date.getDate() )).slice(-2)+ '-' + (`0`+(date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();
						} 
						
						
							else {
							this.startedAt = '';
						}
						let exam=this.modules.filter((module:any)=> module.questionaire )
						if(exam.length>0 ){	
							if(this.userActivity.examStatus){
									this.userActivity.totalModules=this.userActivity.examStatus ? this.userActivity.totalModules+1:this.userActivity.totalModules+0
									var resultPercentage = Math.round((this.userActivity.examStatus.pass / this.userActivity.examStatus.total) * 100);
							if (resultPercentage < 80) {
									this.userActivity.completedModules=this.userActivity.completedModules+0
							} else {
									this.userActivity.completedModules=this.userActivity.completedModules+1
							}
							}else{
								this.userActivity.totalModules=this.userActivity.totalModules+1
							}
					
						}
						else{
							this.userActivity.totalModules=this.userActivity.totalModules+0
						}
						let moduleCompletion = Math.round((this.userActivity.completedModules / this.userActivity.totalModules) * 100) || 0;
							if(moduleCompletion>100){
								moduleCompletion =100
							}
						this.series.push(moduleCompletion);
					});
				})
				.catch((error: any) => {
					toast.error(error, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
		onExit(){
			window.close()
		},
		startCourse(module: any): any {
			var left = (screen.width - 900) / 2;
			var top = (screen.height - 600) / 4;
			let payload={
				totalModules:this.modules.length
			}
				this.$http
					.post(`${process.env.VUE_APP_TRAINING_API_URL}/user/${this.userInfo._id}/course/${this.$route.params.courseId}/updateModuleInUserActivity`, payload)
					.then((response: any) => {
					console.log(response)
					});
			
			// this.$router.push({
			// 	name: 'course-modules', params: { courseId: this.$route.params.courseId }
			// });
			let routeData = this.$router.resolve({ name: 'course-modules', params: { courseId: this.$route.params.courseId } });
			// window.open(routeData.href,"_blank", "width="+screen.availWidth+",height="+screen.availHeight)
			
     		 let pageWindow = window.open(routeData.href, '_blank', 'width=' + screen.availWidth + ',height=' + screen.availHeight);
      			if (pageWindow) {
        			// Listen for messages from Page B
        			window.addEventListener('message', this.handlePage);
      				}
 
			// window.open(routeData.href,'_blank','toolbar=yes,scrollbars=yes')
			// this.closedroute = window.open(routeData.href,'_blank,toolbar=yes,scrollbars=yes,resizable=yes');
			// this.new_window = window.open(routeData.href, '_blank', 'width=900, height=580, top=' + top + ',left=' + left + ',toolbar=yes,scrollbars=yes,resizable=yes fullscreen');
		},
		  			handlePage(event:any) {
     			 if (event.data === 'pageClosed') {
        				// Page B is closed, refresh Page A
       				 location.reload();
      				}
			},
		async downloadCerificate(userInfo:any){
					this.$http({
                    url: `${process.env.VUE_APP_TRAINING_API_URL}/user/${userInfo._id}/course/${this.userActivity.masterCourseId}/certificate`, // File URL Goes Here
                    method: 'GET',
                    responseType: 'blob',
                }).then((res:any) => {
                     var FILE = window.URL.createObjectURL(new Blob([res.data]));
                     var docUrl = document.createElement('a');
                     docUrl.href = FILE;
                     docUrl.setAttribute('download', `${this.courseDetails.courseName}.pdf`);
                     document.body.appendChild(docUrl);
                     docUrl.click();
                });
			
		}
		// downloadCerificate:function(userInfo:any):any{
		// 	return `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_TRAINING_API_PATH}/user/${userInfo._id}/course/${this.userActivity.masterCourseId}/certificate`;
		// }
		
	},

	async created() {
		await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/get`).then((res: any) => {
			this.courseDetails = res.data[0];
		});
	},
	async mounted() {
		await this.getAllModules();

		this.routesArray=[
			{name:'My Courses',routeName:'user_dashboard'},
			{name:this.courseDetails.courseName,routeName:'course-details'},
		]
	}
});
</script>