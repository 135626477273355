<template>
    <div class="pt-3 px-5">
		<div class="mb-2">
			<breadCrumb :routesArray="routesArray"></breadCrumb>
		</div>
        <div class="row w-full flex justify-between">
			<h1 :class="datacourse[0] != undefined && datacourse[0].courseName.length > 40? 'table-ellipsis w-1/2 h-6':''">{{datacourse[0] != undefined ? datacourse[0].courseName : ''}}</h1>
            <div class="flex items-center">
                 <button class="ml-3  btnprimary"  @click="addCoursesData">
                    Add
                </button>
                <button class="ml-3 flex" :class="courseData.length != 0  ? 'filterButton' : 'btndisabled pointer-events-none'" @click="filterOpen()">
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
            </div>
        </div>
         <div class="mt-3">
            <p class="text-sm">
                Course management systems refer to virtual learning environments that are categorized based on pedagogical tools such as content creation,communication,administration and assessment.planning ahead.Requesting what you need (e.g.,materials,equipment,rooms)well in advance keeping careful records.Observing FERPA regulations protecting student privacy.Communicating clearly and often.
            </p>
        </div>
        <div class="mt-3">
            <newTable :tableRowData="courseData" :headersData="Headers" :openPopup="true"></newTable>
        </div>

        <popup v-if="singlecourse.isShow" :popUp="singlecourse.isShow">
				<template v-slot:header>
					<div class="flex items-center justify-between popupheadingcontainer rounded">
						<h1 :class="datacourse[0] != undefined && datacourse[0].courseName.length > 40? 'table-ellipsis w-1/2 h-6':''">{{ datacourse[0].courseName }}</h1>
						<button class="float-right text-3xl" @click="onCrossBtn()">
							<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
						</button>
					</div>
				</template>
				<div>
					<div>
						<div class="statuscontainer popupbodycontainer">
							<div class="formcontainer flex flex-col">
								<form >
									<div class="row flex flex-row w-full upperspacing">
										<div class="w-6/12">
											<label class="controllabel">Course Type</label>
											<select
												class="inputboxstyling"
												v-model="locationBasedOptions.courseTypeCodeId"
												:class="{
													'is-invalid': v$.locationBasedOptions.courseTypeCodeId.$error
												}"
											>

												<option  value="" selected disabled hidden>Select Course Type</option>
												<option v-for="option in courseTypeCodeId" :key="option.value" :value="option.value" >{{option.label}}</option>
											
											</select>
											<div v-if="v$.locationBasedOptions.$error">
												<div v-if="v$.locationBasedOptions.courseTypeCodeId.required.$invalid" class="text-red-500 error-text">Required</div>
											</div>
										</div>
										<div class="w-6/12 leftspacing">
											<label class="controllabel">Max Duration (In Days)</label>
											<input
												
												type="number"
												class="inputboxstyling"
												v-model="locationBasedOptions.dueDays"
												
												:class="{
													'is-invalid': v$.locationBasedOptions.dueDays.$error || this.locationBasedOptions.dueDays==0 || this.locationBasedOptions.dueDays<0
												}"
												placeholder="Please enter course duration"
											/>
											<div v-if="v$.locationBasedOptions.$error || this.locationBasedOptions.dueDays==0 || this.locationBasedOptions.dueDays < 0">
												<div v-if="v$.locationBasedOptions.dueDays.required.$invalid" class="text-red-500 error-text">Required</div>
												<div v-else-if="v$.locationBasedOptions.dueDays.numeric.$invalid" class="text-red-500 error-text">Value should be numeric</div>
												<div v-else-if="this.locationBasedOptions.dueDays==0" class="text-red-500 error-text">value should not be zero</div>
												<!-- <div v-else-if="this.locationBasedOptions.dueDays < 0" class="text-red-500 error-text">value should be greater than  zero</div> -->

											</div>
										</div>
									</div>
									
									<div class="row flex flex-row w-full upperspacing">
										<div class="w-6/12">
											<label class="controllabel">Course Expiry (In Days)</label>
											<input
												type="number"
												class="inputboxstyling"
												v-model="locationBasedOptions.courseExpiry"
												:class="{
													'is-invalid': v$.locationBasedOptions.courseExpiry.$error || locationBasedOptions.courseExpiry==0 ||locationBasedOptions.courseExpiry < locationBasedOptions.dueDays
												}"
												placeholder="Please enter Course Expiry"
											/>
											<div v-if="v$.locationBasedOptions.$error || locationBasedOptions.courseExpiry==0 || locationBasedOptions.courseExpiry < locationBasedOptions.dueDays">
												<div v-if="v$.locationBasedOptions.courseExpiry.required.$invalid" class="text-red-500 error-text">Required</div>
												<div v-else-if="v$.locationBasedOptions.courseExpiry.numeric.$invalid" class="text-red-500 error-text">Value should be numeric</div>
												<div v-else-if="locationBasedOptions.courseExpiry==0" class="text-red-500 error-text">Value should not be zero</div>
												<div v-else-if="locationBasedOptions.courseExpiry < locationBasedOptions.dueDays" class="text-red-500 error-text">Value should  be greater than Max Duration</div>
											</div>
										</div>
										<div class="w-6/12 leftspacing">
											<label class="controllabel">Access</label>
											<select
												class="inputboxstyling"
												@change="globalAccess"
												v-model="locationBasedOptions.type"
												:class="{
													'is-invalid': v$.locationBasedOptions.type.$error
												}"
											>
												<option value="" selected  disabled hidden>Please select Access type</option>
												<option v-for="option in type" :key="option.id" :value="option.typeId" :class="{ hidden: option.typeId === 20302 }">
													{{ option.data }}
												</option>
											</select>
											<div v-if="v$.locationBasedOptions.type.$error" class="text-red-500">
												<div class="error-text" v-if="v$.locationBasedOptions.type.required.$invalid">Required</div>
											</div>
										</div>
										<!-- <div class="w-6/12 p-2">
															<label class="controllabel">Target Audience</label>
															<select
																class="inputboxstyling"
																@change="changeTarget(singlecourse)"
																v-model="singlecourse.courseTargetTypeCodeId"
																:class="{
																	'is-invalid': v$.allCourses.$error && !v$.allCourses.$each.$response.$data[scIndex].courseTargetTypeCodeId.required
																}"
															>
																<option value="10502">Everyone</option>
																<option value="10503">Team(s)</option>
																<option value="10501">User(s)</option>
															</select>
															<div v-if="v$.allCourses.$error">
																<div v-if="!v$.allCourses.$each.$response.$data[scIndex].courseTargetTypeCodeId.required" class="text-red-500">Required</div>
															</div>
														</div> -->
									</div>
									<!-- <div class="w-full p-2" v-if="singlecourse.courseTargetTypeCodeId == 10503">
														<div>
															<label class="controllabel font-normal mt-2">Teams (Max. 10)</label>
															<Multiselect
																@select="changedInputControlValue(locationBasedOptions)"
																@deselect="changedInputControlValue(locationBasedOptions)"
																v-model="singlecourse.department"
																mode="tags"
																:max="10"
																:options="optionsarray"
																:class="{
																	'is-invalid': v$.allCourses.$error && !v$.allCourses.$each.$response.$data[scIndex].department.required && dummyStatusCodeId == singlecourse.courseTargetTypeCodeId,
																	'multiselect_height': singlecourse.department.length != 0
																}"
															/>
														</div>
														<div v-if="v$.allCourses.$error && dummyStatusCodeId == singlecourse.courseTargetTypeCodeId">
															<div v-if="!v$.allCourses.$each.$response.$data[scIndex].department.required" class="text-red-500">Required</div>
														</div>
													</div> -->
									<!-- <div class="w-full p-2" v-if="locationBasedOptions.courseTargetTypeCodeId == 10501"> -->
									<!-- <div>
															<label class="controllabel font-normal mt-2">Users (Max. 10)</label>
															<Multiselect
																@select="changedInputControlValue(locationBasedOptions)"
																@deselect="changedInputControlValue(locationBasedOptions)"
																v-model="singlecourse.user"
																mode="tags"
																:max="10"
																:close-on-select="false"
																:searchable="true"
																:create-option="true"
																:options="optionsUserArray"
																:class="{
																	'is-invalid': v$.allCourses.$error && !v$.allCourses.$each.$response.$data[scIndex].user.required && dummyStatusCodeId == singlecourse.courseTargetTypeCodeId,
																	'multiselect_height': singlecourse.user.length != 0
																}"
															/>
														</div> -->
									<!-- {{scIndex}} -->
									<!-- <div v-if="v$.allCourses.$error && dummyStatusCodeId == singlecourse.courseTargetTypeCodeId">
															<div v-if="!v$.allCourses.$each.$response.$data[scIndex].user.required" class="text-red-500">Required</div>
														</div> -->
									<!-- </div> -->
									<div class="row flex flex-row w-full">
										<div v-if="locationBasedOptions.type == 20303" class="w-6/12 upperspacing">
											<label class="controllabel">Entitys</label>
											<select
												class="inputboxstyling"
												v-model="locationBasedOptions.entityId"
												@change="getEntityTeamsList(locationBasedOptions.entityId)"
												:class="{
													'is-invalid': v$.locationBasedOptions.entityId.$error
												}"
											>
												<option value="" selected disabled hidden>Please select entities</option>
												<option v-for="(option, index) in entitesDataArray" :key="index" :value="option._id">{{ option.entityName }}</option>
											</select>
											<div v-if="v$.locationBasedOptions.entityId.$error" class="text-red-500">
												<div class="error-text" v-if="v$.locationBasedOptions.entityId.required.$invalid">Required</div>
											</div>
										</div>
										<div v-if="locationBasedOptions.type == 20303 && locationBasedOptions.entityId" class="upperspacing leftspacing w-6/12">
											<label class="controllabel">Target Audience</label>
											<select
												class="inputboxstyling"
												v-model="locationBasedOptions.courseTargetTypeCodeId"
												@change="handleAccessLevelTwoChange"
												:class="{
													'is-invalid': v$.locationBasedOptions.courseTargetTypeCodeId.$error
												}"
											>
												<option value="" selected disabled hidden>Please select Target Audience</option>
												<option v-for="option in accessLevelTwo" :key="option.id" :value="option.courseTargetTypeCodeId" :class="{ hidden: option.id == 10503 }">{{ option.data }}</option>
											</select>
											<div v-if="v$.locationBasedOptions.courseTargetTypeCodeId.$error" class="text-red-500">
												<div class="error-text" v-if="v$.locationBasedOptions.courseTargetTypeCodeId.required.$invalid">Required</div>
											</div>
										</div>
										<div v-if="locationBasedOptions.type == 20302" class="upperspacing leftspacing w-6/12">
											<label class="controllabel">Region Access</label>
											<select
												class="inputboxstyling"
												v-model="locationBasedOptions.region"
												@change="regionAccesshandler(locationBasedOptions.region)"
												:class="{
													'is-invalid': v$.locationBasedOptions.region.$error
												}"
											>
												<option value="" selected disabled hidden>Please select Region</option>
												<option v-for="(option, index) in regionDataArray" :key="index" :value="option._id">{{ option.region }}</option>
											</select>
										</div>
										<div v-if="locationBasedOptions.type == 20301" class="upperspacing w-6/12">
											<label class="controllabel">Global Access</label>
											<select
												class="inputboxstyling"
												v-model="locationBasedOptions.courseTargetTypeCodeId"
												@change="handleAccessLevelTwoChange"
												:class="{
													'is-invalid': v$.locationBasedOptions.courseTargetTypeCodeId.$error
												}"
											>
												<option value="" selected disabled hidden>Please select Target Audience</option>
												<option v-for="option in accessLevelTwo" :key="option.id" :value="option.courseTargetTypeCodeId" :class="{ hidden: option.courseTargetTypeCodeId == 10503 || option.courseTargetTypeCodeId == 10501 }">{{ option.data }}</option>
											</select>
											<div v-if="v$.locationBasedOptions.courseTargetTypeCodeId.$error" class="text-red-500">
												<div class="error-text" v-if="v$.locationBasedOptions.courseTargetTypeCodeId.required.$invalid">Required</div>
											</div>
										</div>
									</div>
									<div class="row flex flex-row w-full">
										<div v-if="locationBasedOptions.type == 20301 && locationBasedOptions.courseTargetTypeCodeId == 10502" class="w-full upperspacing">
											<label class="controllabel">Teams</label>
											<multiselect
												v-model="locationBasedOptions.departments"
												class="custom-multiselect"
												:options="globalTeams"
                                                :showNoOptions="false"
												:searchable="true"
                                                :multiple="true"
                                                :hide-selected="true"
                                                :close-on-select="false"
                                                :clear-on-select="true"
                                                open-direction="bottom"
                                                label="label"
                                                track-by="value"    
                                                :hideArrow="false"
												placeholder="search Global Teams"
												:class="{
													'is-invalid borderRed':v$.locationBasedOptions.departments.$error
												}"
											>
											<template v-slot:noResult>
												<span>No teams Found</span>
											</template>
											</multiselect>
											<div v-if="v$.locationBasedOptions.departments.$error" class="text-red-500">
												<div class="error-text" v-if="v$.locationBasedOptions.departments.required.$invalid">Required</div>
											</div>
											
										</div>
										<!-- <div v-if="locationBasedOptions.type == 1 && locationBasedOptions.courseTargetTypeCodeId == 10503" class="p-2 w-6/12">
														<label class="controllabel">Users</label>
														<Multiselect
															@select="changedInputControlValue(locationBasedOptions)"
															@deselect="changedInputControlValue(locationBasedOptions)"
															v-model="locationBasedOptions.user"
															mode="tags"
															:max="10"
															:close-on-select="false"
															:searchable="true"
															:create-option="true"
															:options="entitysTeams"
															placeholder="Suggest Teams"
														></Multiselect>

														<div v-if="v$.locationBasedOptions.users.$error" class="text-red-500">
															<div class="error-text" v-if="v$.locationBasedOptions.users.required.$invalid">Required</div>
														</div>
													</div> -->
										<!-- {{singlecourse.entity}} -->
										<div v-if="locationBasedOptions.type == 20302 && locationBasedOptions.region" class="upperspacing w-6/12">
											<label class="controllabel">Entity</label>

											<select
												class="inputboxstyling"
												v-model="locationBasedOptions.entityId"
												@change="getEntityTeamsList(locationBasedOptions.entityId)"
												:class="{
													'is-invalid': v$.locationBasedOptions.entityId.$error
												}"
											>
												<option value="" selected disabled hidden>Please select Entities</option>
												<option v-for="option in entityData1" :key="option.id" :value="option._id">{{ option.entityName }}</option>
											</select>
											<!-- <div v-if="v$.locationBasedOptions.entity.$error" class="text-red-500">
																<div class="error-text" v-if="v$.locationBasedOptions.entity.required.$invalid">Required</div>
															</div> -->
										</div>
										<div v-if="locationBasedOptions.type == 20302 && locationBasedOptions.region && locationBasedOptions.entityId" class="upperspacing leftspacing w-6/12">
											<label class="controllabel">Target Audience</label>
											<select
												class="inputboxstyling"
												v-model="locationBasedOptions.courseTargetTypeCodeId"
												@change="handleAccessLevelTwoChange"
												:class="{
													'is-invalid': v$.locationBasedOptions.courseTargetTypeCodeId.$error
												}"
											>
												<option value="" selected disabled hidden>Please select Target Audience</option>
												<option v-for="option in accessLevelTwo" :key="option.id" :value="option.courseTargetTypeCodeId" :class="{ hidden: option.id == 10503 }">{{ option.data }}</option>
											</select>
											<div v-if="v$.locationBasedOptions.courseTargetTypeCodeId.$error" class="text-red-500">
												<div class="error-text" v-if="v$.locationBasedOptions.courseTargetTypeCodeId.required.$invalid">Required</div>
											</div>
										</div>
									</div>
									<div class="row flex flex-row w-full">
										<div v-if="locationBasedOptions.type == 20302 && locationBasedOptions.courseTargetTypeCodeId == 10502" class="upperspacing w-full">
											<label class="controllabel">Teams</label>
											<!-- <multiselect
												v-model="locationBasedOptions.departments"
												class="custom-multiselect"
												:options="entitysTeams"
                                                :showNoOptions="false"
                                                :multiple="true"
                                                :hide-selected="true"
                                                :close-on-select="true"
                                                :clear-on-select="true"
                                                open-direction="bottom"
                                                label="label"
                                                track-by="value"    
                                                :hideArrow="false"
												placeholder="Search Entity Teams"
												:class="{
													'is-invalid borderRed':v$.locationBasedOptions.departments.$error
												}"
											>
											<template v-slot:noResult>
												<span>No teams Found</span>
											</template>
											</multiselect>
											<div v-if="v$.locationBasedOptions.departments.$error" class="text-red-500">
												<div class="error-text" v-if="v$.locationBasedOptions.departments.required.$invalid">Required</div>
											</div> -->
										</div>
										<div v-if="locationBasedOptions.type == 20302 && locationBasedOptions.courseTargetTypeCodeId == 10503" class="upperspacing w-full">
											<label class="controllabel">Users</label>
											<!-- <Multiselect
											
												v-model="locationBasedOptions.users"
												mode="tags"
												:max="10"
												:close-on-select="false"
												:searchable="true"
												:create-option="true"
												:options="entitysUsers"
												placeholder="Suggest Users"
												:class="{
													'is-invalid': v$.locationBasedOptions.users.$error
												}"
											></Multiselect> -->
										</div>
										<div v-if="locationBasedOptions.type == 20303 && locationBasedOptions.courseTargetTypeCodeId == 10502" class="upperspacing w-full">
											<label class="controllabel">Teams</label>
											<multiselect
												v-model="locationBasedOptions.departments"
												class="custom-multiselect"
												:options="entitysTeams"
												:searchable="true"
                                                :showNoOptions="false"
                                                :multiple="true"
                                                :hide-selected="true"
                                                :close-on-select="false"
                                                :clear-on-select="true"
                                                open-direction="bottom"
                                                label="label"
                                                track-by="value"    
                                                :hideArrow="false"
												placeholder="Search Entity Teams"
												:class="{
													'is-invalid borderRed':v$.locationBasedOptions.departments.$error
												}"
											>
											<template v-slot:noResult>
												<span>No Teams Found</span>
											</template>
											</multiselect>
											<div v-if="v$.locationBasedOptions.departments.$error" class="text-red-500">
												<div class="error-text" v-if="v$.locationBasedOptions.departments.required.$invalid">Required</div>
											</div>
										</div>
										<div v-if="locationBasedOptions.type == 20303 && locationBasedOptions.courseTargetTypeCodeId == 10503" class="upperspacing w-full">
											<label class="controllabel">Users</label>
												<multiselect
												v-model="locationBasedOptions.users"
												class="custom-multiselect"
												:options="entitysUsers"
                                                :showNoOptions="false"
												:searchable="true"
                                                :multiple="true"
                                                :hide-selected="true"
                                                :close-on-select="false"
                                                :clear-on-select="true"
                                                open-direction="bottom"
                                                label="label"
                                                track-by="value"    
                                                :hideArrow="false"
												placeholder="Search Users"
												:class="{
													'is-invalid borderRed':v$.locationBasedOptions.users.$error
												}"
											>
											<template v-slot:noResult>
												<span>No Users Found</span>
											</template>
											</multiselect>
											<div v-if="v$.locationBasedOptions.users.$error" class="text-red-500">
												<div class="error-text" v-if="v$.locationBasedOptions.users.required.$invalid">Required</div>
											</div>

										</div>
									</div>
									<div class="flex items-center justify-start">
										<input type="checkbox" id="forceUpdate" v-model="locationBasedOptions.forceUpdate" class="cursor-pointer checkbox" />
										<label for="forceUpdate" class="text-sm ml-2 mt-2 cursor-pointer w-full text-left controllabel">Force Update</label>
									</div>

									<div class="flex justify-center">
										<div class="flex justify-center items-center" v-if="addPopupData">
											<button
												type="button"
												:class="allEmptyValues ? 'btndisabled pointer-events-none' : 'btnprimary '"
												@click="cancelPermissions1()"
											>
												Cancel
											</button>
										</div>
										<div class="flex justify-center items-center" v-else>
											<button
												type="button"
												:class="compareObjects ? 'btnprimary' : 'btndisabled pointer-events-none'"
												@click="cancelPermissions()"
											>
												Cancel
											</button>
										</div>

						
										<div class="flex justify-center items-center ml-6" v-if="addPopupData" >
											<button
												type="button"
												:class="allEmptyValues ? 'btndisabled pointer-events-none' : 'btnprimary '"
												@click="savePermissions()"
											>
												Save
											</button>
										</div>

										<div class="buttonposition flex justify-center items-center ml-6" v-else>
											<button
												type="button"
												
												:class="compareObjects ? 'btnprimary' : 'btndisabled pointer-events-none'"
												@click="updatePermissions(showIndex)"
											>
												Update
											</button>
										</div>
										<!-- <div class="flex justify-center items-center" v-if="singlecourse.updatedAt == '' || !singlecourse.updatedAt">
											<button type="button" class="btnprimary mt-2 ml-6" @click="resetCoursePermissions()">Reset</button>
										</div>
										<div class="flex justify-center items-center" v-else>
											<button type="button" class="btnprimary mt-2 ml-6" @click="resetCoursePermissions()">Reset</button>
										</div> -->
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</popup>
    </div>
</template>
<style scoped>
.table-ellipsis {
  display:-webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

</style>

<script lang="ts">
import newTable from '@/components/newTable.vue';
import {required,requiredIf,helpers} from '@vuelidate/validators';
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import popup from '@/components/popup.vue';
import { mapGetters } from 'vuex';
import { emitter, toast } from '@/main';
import breadCrumb from '@/components/breadCrumb.vue'
import Multiselect from 'vue-multiselect'
const numeric = helpers.regex(/^[0-9]*$/);
import _ from 'lodash';

export default defineComponent({
    data():any{
        return{
            v$: useVuelidate(),
            singlecourse:{},
            courseData:[],
			datacourse:[],
			routesArray:[],
            Headers:['type','entityName','courseTargetTypeCodeId','userCount','courseTypeCodeId','dueDays','courseExpiry','deleteIcon'],
			allCourses: [],
			tabledataBinding:0,
			isShow: false,
			teamsList: [],
			value: null,
			// optionsarray: [],
			optionsUserArray: [],
			department: [],
			users: [],
			allUsers: [],
			locationBasedOptions: {count:null},
			locationBasedOptions_dummy: {},
			courseAssigned: [],
			dummyListForAllCourses: [],
			activateButton: false,
			updatescreen: false,
			dummyObj:{},
			statusCodeId: '',
			dummyStatusCodeId: '',
			dummyTeam: [],
			dummyUser: [],
			index: -1,
			newScreen: false,
			fileData: '',
			image: '',
			data: '',
			base64String: '',
			imageBase64Stringsep: '',
			dummySingleCourse: {},
			teamsDataArray: [],
			entitesDataArray: [],
			regionDataArray: [],
			globalTeams: [],
			globalTeamsDataArray: [],
			entitysTeams: [],
			entitysUsers: [],
			allUserArray: [],
			updateDetails:{},
			showIndex:'',
			objectData:'',
			departmentsCount:null,

			type: [
				{
					typeId: 20301,
					data: 'Global'
				},
				{
					typeId: 20302,
					data: 'Region'
				},
				{
					typeId: 20303,
					data: 'Entity'
				}
			],
			accessLevelTwo: [
				{ courseTargetTypeCodeId: 10501, data: 'EveryOne' },
				{
					courseTargetTypeCodeId: 10502,
					data: 'Teams'
				},
				{ courseTargetTypeCodeId: 10503, data: 'Users' }
			],
            courseTypeCodeId: [
                {
                    value: 10601,
                    label: 'Mandatory'
                },
                {
                    value: 10602,
                    label: 'Optional'
                }
			],
			orgUsersList: [],
			entityData1: [],
			coursePermissions: [],
			coursePermissions_dummy: [],
			dataSubmitted: false,
			// formTouched: false,
			newlyAddedCoursePermissions: [],
			readonlyDepts: [],
			existingReadonlyDepts: [],
			readonlyDepts_dummy: [],
			readonlyUsers: [],
			existingReadonlyUsers: [],
			readonlyUsers_dummy: [],
			dummy_locationBasedOptions:{},
			addPopupData:false,
			courseDataPermissionArray:[],
			entityId:''
		};
        },
        components: { 
			newTable,
			Multiselect, 
			popup,
			breadCrumb 
		},
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
		allEmptyValues: function (): any {
			let valueArray: any = [];
			let result = true
			Object.values(this.locationBasedOptions).forEach((value: any) => {
				valueArray.push(value);
			});
			for (let value of valueArray) {
				if (value != '') {
					return false
				}
			}

			return result;
		},
		compareObjects:function():any{
			return !(_.isEqual(this.locationBasedOptions,this.dummy_locationBasedOptions))
		}

	},
	validations(index: any) {
		// console.log("index",index)
		return {
			// allCourses: {
			// 	$each: helpers.forEach({
			// 	})
			// },
			locationBasedOptions: {
				courseTypeCodeId: { required },
				dueDays: { required, numeric },
				courseExpiry: { required, numeric },
				type: { required },
				region: {
					required: requiredIf(() => {
						return this.locationBasedOptions.type == 20302;
					})
				},

				entityId: {
					required: requiredIf(() => {
						return (this.locationBasedOptions.type === 20302 && this.locationBasedOptions.region) || this.locationBasedOptions.type === 20303;
					})
				},
				courseTargetTypeCodeId: {
					required: requiredIf(() => {
						return this.locationBasedOptions.type === 20301 || (this.locationBasedOptions.type === 20302 && this.locationBasedOptions.region && this.locationBasedOptions.entityId) || (this.locationBasedOptions.type === 20303 && this.locationBasedOptions.entityId);
					})
				},

				departments: {
					required: requiredIf(() => {
						return (
							(this.locationBasedOptions.type === 20301 && this.locationBasedOptions.courseTargetTypeCodeId === 10502) ||
							(this.locationBasedOptions.type === 20302 && this.locationBasedOptions.region && this.locationBasedOptions.entityId && this.locationBasedOptions.courseTargetTypeCodeId === 10502) ||
							(this.locationBasedOptions.type === 20303 && this.locationBasedOptions.entityId && this.locationBasedOptions.courseTargetTypeCodeId == 10502)
						);
					})
				},
				users: {
					required: requiredIf(() => {
						return (
							(this.locationBasedOptions.type === 20301 && this.locationBasedOptions.courseTargetTypeCodeId === 10503) ||
							(this.locationBasedOptions.type === 20302 && this.locationBasedOptions.region && this.locationBasedOptions.entityId && this.locationBasedOptions.courseTargetTypeCodeId === 10503) ||
							(this.locationBasedOptions.type === 20303 && this.locationBasedOptions.entityId && this.locationBasedOptions.courseTargetTypeCodeId == 10503)
						);
					})
				}
			}
		};
	},
    methods:{
		 filterOpen() {
			console.log("ooooooo");
			
            this.showFilters = !this.showFilters;
            emitter.emit('visible', this.showFilters);
        },
		addCoursesData(){			
			this.singlecourse.isShow=!this.singlecourse.isShow;
			this.locationBasedOptions={courseTypeCodeId:'',courseTargetTypeCodeId:'',type:'',entityId:''};
			this.addPopupData = true
		},
		resetCoursePermissions() {
			this.locationBasedOptions = { type: '', courseTargetTypeCodeId: '', courseTypeCodeId: '', entity: '', region: '', departments: [], users: [] };
			this.locationBasedOptions_dummy = { type: '', courseTargetTypeCodeId: '', courseTypeCodeId: '', entity: '', region: '', departments: [], users: [] };
			this.singlecourse = {};
			this.singlecourse.isShow = true;
			this.coursePermissions = [];
			// Clear course permissions
			this.newlyAddedCoursePermissions = [];
			this.readonlyDepts_dummy = [];
			this.readonlyUsers_dummy = [];

			// Reset form validation state
			this.v$.$reset();
		},
		cancelPermissions1() {
			this.singlecourse.isShow = true;
			this.locationBasedOptions = {...this.dummy_locationBasedOptions}
			this.locationBasedOptions = { "courseTypeCodeId": "", "courseTargetTypeCodeId": "","type":"","entityId":""};
			this.v$.$reset();
		},
		cancelPermissions() {
			this.singlecourse.isShow = true;
			
			this.locationBasedOptions = {...this.dummy_locationBasedOptions} ;

		},
		onCrossBtn(){
			this.singlecourse.isShow = false,
			this.singlecourse.updatescreen = false
			this.v$.$reset();
		},

		savePermissions() {

			this.v$.locationBasedOptions.$touch();
		 	if(!this.v$.locationBasedOptions.$invalid && this.locationBasedOptions.dueDays!=0 && this.locationBasedOptions.courseExpiry > this.locationBasedOptions.dueDays && this.locationBasedOptions.dueDays > 0) {
				// Reset form and hide screens
				this.v$.$reset();
				this.singlecourse.isShow = false;
				this.singlecourse.updatescreen = false;
				this.entityId = this.locationBasedOptions.entityId;
				console.log("this.entityId",this.entityId);
				

				if(this.locationBasedOptions.departments.length != 0){		
				if (this.locationBasedOptions.departments.length > 0) {
					this.locationBasedOptions.departments = this.locationBasedOptions.departments.map((departmentId: any) => {
						const department = this.teamsDataArray.find((dept: any) => dept._id === departmentId.value);
						return department ? { departmentName: department.teamName, departmentId: department._id } : null;
					})
					.filter(Boolean); // Remove any null values
				}
			}
				if(this.locationBasedOptions.users.length != 0){
				if(this.locationBasedOptions.users.length > 0) {
					this.locationBasedOptions.users = this.locationBasedOptions.users.map((userID:any) => {					
						const userInfo = this.allUserArray.find((user:any) => user.value == userID.value);
						return userInfo ? {userEmail : userInfo.email, userId: userInfo.value , userName: userInfo.label} : null;
					})
					.filter(Boolean);
				}
				}
				
				this.updateDetails = {
					masterCourseId: this.datacourse[0].masterCourseId,
					courseName: this.datacourse[0].courseName,					
					coursePermissions:[this.locationBasedOptions] // Sending only newly added permissions
				};
				
                this.singlecourse.isShow=!this.singlecourse.isShow;

				// HTTP POST request
				this.$http
					.post(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/course/${this.datacourse[0]._id}`,this.updateDetails)
					.then(async (response: any) => {
						this.singlecourse.show=false;
						if (response.status === 200) {
							toast.info('Updated', {
								timeout: 1000,
								closeOnClick: true,
								closeButton: 'button',
								icon: true
							});
							this.singlecourse.isShow = false;
							this.createscreen = true;
						}
						await this.getAllCourses();
					})
					.catch((error: any) => {
						this.singlecourse.show=false;
						toast.error('Not Updated', {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					});
			}else{
				toast.error('cannot Save', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
				//this.updateDetails.coursePermissions=[];	
			}
		},
		updatePermissions(index:any){
			console.log("djjdj")
			this.v$.locationBasedOptions.$touch();
			this.v$.$reset();
		 	if (!this.v$.locationBasedOptions.$invalid  && this.locationBasedOptions.dueDays!=0 && this.locationBasedOptions.courseExpiry>this.locationBasedOptions.dueDays) {
				// Reset form and hide screens
			
				this.singlecourse.isShow = false;
				this.singlecourse.updatescreen = false;
					if(this.locationBasedOptions.courseTargetTypeCodeId ==10502 ){
						this.locationBasedOptions.users=[];
						if (this.locationBasedOptions.departments.length > 0) {
						this.locationBasedOptions.departments = this.locationBasedOptions.departments.map((departmentId: any) => {
						const department = this.teamsDataArray.find((dept: any) => dept._id === departmentId.value);

						
						return department ? { departmentName: department.teamName, departmentId: department._id } : null;
					})
					.filter(Boolean); // Remove any null values
				}
					}
					else if(this.locationBasedOptions.courseTargetTypeCodeId ==10503 ){
						this.locationBasedOptions.departments=[];
						if(this.locationBasedOptions.users.length > 0) {
						this.locationBasedOptions.users = this.locationBasedOptions.users.map((userID:any) => {					
						const userInfo = this.allUserArray.find((user:any) => user.value == userID.value);
						return userInfo ? {userEmail : userInfo.email, userId: userInfo.value , userName: userInfo.label} : null;
					})
					.filter(Boolean);
				}
				}
				this.updateDetails = {...this.locationBasedOptions };
                this.singlecourse.isShow=!this.singlecourse.isShow;

				
				// HTTP POST request
				this.$http
					.post(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/course/${this.datacourse[0]._id}/${index}/updatePermissions`,this.updateDetails)
					.then(async (response: any) => {
						this.singlecourse.isShow=false;
						if (response.status === 200) {
							toast.info('Updated data', {
								timeout: 1000,
								closeOnClick: true,
								closeButton: 'button',
								icon: true
							});
							this.singlecourse.isShow = false;
							this.createscreen = true;
						}
						await this.getAllCourses();
						this.entitysUsers = []
						this.globalTeams=[];

					})
					.catch((error: any) => {
						this.singlecourse.isShow=false;
						toast.error('Not Updated data', {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
						// this.singlecourse.isShow = true;
				
					});
			}
			else{
				toast.error('Cannot Update', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});

			}

		},
		
		async getallTeamMembers() {
			// Initialize arrays
			this.allUserArray = [];
			this.optionsUserArray = [];
			// Fetch user data from the API
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
				// Filter out inactive users				
				this.orgUsersList = res.data.filter((user: any) => user.isActive == true);
				
				// Build arrays for user options
				for (var i = 0; i < this.orgUsersList.length; i++) {
					let obj: any = {value: this.orgUsersList[i]._id, label: this.orgUsersList[i].name,email:this.orgUsersList[i].email, entity: this.orgUsersList[i].entity};
					
					// Exclude current user from options
					if (this.orgUsersList[i]._id !== this.userInfo._id) {
						this.optionsUserArray.push(obj);	
					}
					this.allUserArray.push(obj);	
				}
			});
		},
		async getAllRootCourses() {
			// Fetch all root courses from the API
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/courses/getAll`).then((res: any) => {
				this.allRootCourses = res.data;
			});
		},
		async getAllCourses() {
			this.courseData = [];
			// Fetch all courses from the API
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/courses`).then((res: any) => {
				this.allCourses = res.data;
				console.table(this.allCourses);
				// Update course data with corresponding root course data
				for (var i = 0; i < this.allCourses.length; i++) {
					for (var j = 0; j < this.allRootCourses.length; j++) {
						if (this.allCourses[i].masterCourseId == this.allRootCourses[j]._id) {
							this.allCourses[i].courseDescription = this.allRootCourses[j].courseDescription;
							this.allCourses[i].courseImage = this.allRootCourses[j].courseImageUrl;
							if (this.allRootCourses[j].coursePermissions) {
								this.allCourses[i].coursePermissions = this.allRootCourses[j].coursePermissions;
							}
						}
					}
				}
				 this.datacourse=this.allCourses.filter((data:any)=>{
					return data._id==this.$route.params.courseId;
				})
				if(this.datacourse[0].coursePermissions != undefined){
					let array = this.datacourse[0].coursePermissions;
					console.log("arrayyyyy:",array);
					
					array.map((mainObj:any)=>{
						this.entityId=mainObj.entityId;
						
						if(mainObj.users.length !== 0) {
							if(mainObj.users.length > 0){
								mainObj.userCount= mainObj.users.length !=0 ? mainObj.users.length : 0;
								let array: any = []
								mainObj.users.map((obj: any)=>{
									let newObj = this.allUserArray.find((user: any)=>user.value == obj.userId)
									let userObj:any={label:newObj.label,value:newObj.value}
									array.push(userObj);
									// array = [{label:newObj.value,value:newObj.label}]
								})
								mainObj.users = array
							}

						}
						else if(mainObj.departments.length!==0){
							if(mainObj.departments.length > 0) {
								mainObj.userCount = mainObj.departments.length !=0 ? mainObj.departments.length : 0;
								let departmentArray : any = []
								mainObj.departments.map((obj:any)=>{
									console.log("newobj:",obj);
									let newdepartmentObj = this.teamsDataArray.find((department:any)=> department._id == obj.departmentId)
									 let departmentObj :any ={label:newdepartmentObj.teamName,value:newdepartmentObj._id}
									 departmentArray.push(departmentObj);									 
									// departmentArray ={label:newdepartmentObj.teamName,value:newdepartmentObj._id}						
								})
								console.log("departmentsssdataaa:",departmentArray);
								
								mainObj.departments = departmentArray								
							}
						}
						else if (mainObj.courseTargetTypeCodeId == 10501) {
								let existuser = this.allUserArray.filter((users: any) => {
									return users.entity == this.entityId;
								});
								
								mainObj.userCount = existuser.length;								
						}
						
							mainObj.entityName = this.getEntityName(mainObj.entityId)
							this.courseData.push(mainObj);
					})
				}
				else{
					this.courseData = []
				}			
			});
		},
		async getAllTeams() {
			// Initialize teams data array
			 this.teamsDataArray = [];
			// Fetch teams data from the API
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
				// Process each team object
				res.data.map((teamObj: any) => {
					// Calculate entities count based on team type
					if (Object.keys(teamObj).includes('entities') && teamObj.type == 20302) {
						teamObj.entitiesCount = teamObj.entities.length;
					} else if (teamObj.type == 20301) {
						teamObj.entitiesCount = 'Global';
						this.globalTeamsDataArray.push(teamObj);
					} else {
						teamObj.entitiesCount = 0;
					}
					// Push processed team object to teams data array
					this.teamsDataArray.push(teamObj);
				});
			});
		},

		async getAllEntities() {
			// Initialize entities data array
			this.entitesDataArray = [];
			// Fetch entities data from the API
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
				// Filter entities based on query parameters if present
				
				if (Object.keys(this.$route.query).includes('from') && this.$route.query.from == 'region') {
					res.data.map((obj: any) => {
						if (obj.region == this.$route.params.regionId) {
							this.entitesDataArray.push(obj);
						}
					});
				} else {
					this.entitesDataArray = res.data;
				}
			});
		},

		async getAllRegions() {
			// Initialize region data array
			this.regionDataArray = [];
			// Fetch regions data from the API
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/regions/getall`).then((res: any) => {
				// Process each region object
				res.data.map((obj: any) => {
					obj.entities = [];
					// Filter entities for the current region
					let entityFilter = this.entitesDataArray.filter((entity: any) => obj._id == entity.region);

					// Process filtered entities
					if (entityFilter.length > 0) {
						obj.entityCount = entityFilter.length;
						entityFilter.map((entity: any) => {
							obj.entities.push(entity._id);
						});
					} else {
						obj.entityCount = 0;
					}
					// Push processed region object to region data array
					this.regionDataArray.push(obj);
				});
			});
		},
		 async getEntityTeamsList(data: any) {
			// Initialize entity teams array
			
			this.entitysTeams = [];
			this.entitysUsers = [];
			this.locationBasedOptions.departments = [];
			this.locationBasedOptions.users = [];

			// Filtering teams based on entity ID
			// console.log(this.teamsDataArray, 'teams array');
			this.teamsDataArray.filter((data_object1: any) => {
				return data_object1.type == 20302 && data_object1.entity == data
			});

			for (let i = 0; i < this.orgUsersList.length; i++) {				
				if(this.orgUsersList[i].entity== this.locationBasedOptions.entityId) {
					this.entitysUsers.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
				}
			}
			// Resetting course permissions based on entity type
			if (this.singlecourse.type === 3 && data == this.singlecourse.entity) {
				this.singlecourse.courseTargetTypeCodeId = '';
				this.singlecourse.departments = [];
				this.singlecourse.users = [];
			}
			// Resetting form validation
			this.v$.$reset();
		},
		globalAccess(newValue: any, oldValue: any) {
			// Resetting entity teams and users arrays
			// console.log('new val', newValue);
			// console.log('old val', oldValue);
			this.globalTeams = [];
			this.entitysTeams = [];
			this.entitysUsers = [];
			this.locationBasedOptions.courseTargetTypeCodeId = '';
			this.locationBasedOptions.departments = [];

			this.locationBasedOptions.users = [];

			// Populating entity teams and users arrays
			// console.log(this.globalTeamsDataArray,"global teams");
			for (let i = 0; i < this.globalTeamsDataArray.length; i++) {
				this.globalTeams.push({ label: this.globalTeamsDataArray[i].teamName, value: this.globalTeamsDataArray[i]._id });
				
			}
			// console.log(this.teamsDataArray," list of teams");
			for (let i = 0; i < this.teamsDataArray.length; i++) {
				this.entitysTeams.push({ label: this.teamsDataArray[i].teamName, value: this.teamsDataArray[i]._id });
				
			}
			for (let i = 0; i < this.orgUsersList.length; i++) {
				this.entitysUsers.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });				
			}

			// Resetting course permissions if access level changes
			if (newValue !== oldValue) {
				this.singlecourse.courseTargetTypeCodeId = '';
				this.singlecourse.region = '';
				this.singlecourse.entity = '';
				this.singlecourse.departments = [];
				this.singlecourse.users = [];
				this.v$.$reset();
			}
		},

		handleAccessLevelTwoChange(newValue: any, oldValue: any) {
			// console.log('new val lev 2', newValue);
			// console.log('old val lev 2', oldValue);
			// Resetting departments and users if access level two changes
			if (newValue !== oldValue) {
				this.singlecourse.departments = [];
				this.singlecourse.users = [];
				this.v$.$reset();
			}
		},

		cancelCoursePermissions() {
			// Resetting location based options and form validation
			this.locationBasedOptions = { type: '', courseTargetTypeCodeId: '', courseTypeCodeId: '', entity: '', region: '', departments: [], users: [] };
			this.locationBasedOptions_dummy = { type: '', courseTargetTypeCodeId: '', courseTypeCodeId: '', entity: '', region: '', departments: [], users: [] };
			this.v$.$reset();
		},
		removeSubmittedData(index: any) {
			// console.log('before splice', this.coursePermissions);
			let removedPermission = this.newlyAddedCoursePermissions.splice(index, 1);
			this.readonlyDepts.splice(index, 1);
			this.readonlyUsers.splice(index, 1);
			console.log(removedPermission, 'spliced');
			// console.log('after splice', this.coursePermissions);
			if (this.newlyAddedCoursePermissions.length == 0) {
				this.dataSubmitted = false;
			}
		},
		
		getEntityName(entityId: any) {
			let entity;
			
			entity = this.entitesDataArray.find((entityObj: any) => {
				return entityObj._id == entityId;
			});
			if(entity != undefined){
				return entity.entityName;
			}else{
				return '-'
			}
		},
		editInfo(obj:any,index:any){
			this.singlecourse.isShow=!this.singlecourse.isShow;
			this.locationBasedOptions = {...obj}

			this.entitysTeams = [];
			this.globalTeams = [];
			this.entitysUsers = [];
			//this.allUserArray=[];

			let myType:any

			if(obj.type == 20303 || obj.type == 20302){
				myType = 20302
			}else if(obj.type == 20301){
				myType = 20301
			}
			
			let filteredTeams = this.teamsDataArray.filter((team:any)=>{
				
				
				if(team.type==myType&& team.type != 20301){
					// team.entities.filter((data:any)=>{
						if(team.entity == this.locationBasedOptions.entityId) {
							this.entitysTeams.push({label:team.teamName,value:team._id})							
						}	
					// })
				}
				else if(team.type == 20301 && team.type != 20302) {
					this.globalTeams.push({label:team.teamName,value:team._id})									
				}
			})			
			let existingUserIds:any = []
					this.locationBasedOptions.users.map((obj:any)=>{existingUserIds.push(obj.value) });	

			let existuser = this.allUserArray.filter((users:any) =>{				
				if(users.entity == this.locationBasedOptions.entityId) {
					this.entitysUsers.push({label:users.label,value:users.value})
				}
			})
			// this.entitysUsers= this.allUserArray.filter((userData:any)=>{
			// 	// // let data:any= { value: userData.value , label : userData.label}
			// 	//  const data = this.locationBasedOptions.users.filter((data:any)=>{
			// 	// 	console.log("ppppp",userData);
			// 	// 							console.log("666666",userData.value != data.value);
			// 	// 	return userData.value == data.value 
			// 	//  })					 
			// 	// return data ? this.entitysUsers.push(data) : [];
			// 	return !existingUserIds.includes(userData.value)
			// });
			// console.log("aaaaa",this.entitysUsers);
			
			
			for( let i=0;i<this.locationBasedOptions.users.length;i++){
				let userId:any=[this.locationBasedOptions.users[i]];
			}
			this.dummy_locationBasedOptions ={...obj}
			this.addPopupData = false
		}
	},
	async mounted() {

		await this.getAllTeams();
		await this.getAllEntities();
		await this.getallTeamMembers();
		await this.getAllRegions();
		await this.getAllRootCourses();
		//  await this.getAllUsers();
		
		await this.getAllCourses();
		
		emitter.off('showData123');
		emitter.on('showData123',(isData:any)=>{
			if(this.tabledataBinding==0){
				console.log("123456789sdhdsb",isData)
				let showObj=isData.entityData;
				this.showIndex=isData.index;
				console.log(showObj);
				this.editInfo(showObj,this.showIndex)
			}
		})

		emitter.off('RowIndex');
		emitter.on('RowIndex',(index: any)=>{
			this.$http
				.post(`${process.env.VUE_APP_TRAINING_API_URL}/api/org/courses/${this.datacourse[0]._id}/coursePermission/${index}/delete`)
				.then(async (response:any) => {
					if (response.status === 200) {
							toast.info('Deleted', {
								timeout: 1000,
								closeOnClick: true,
								closeButton: 'button',
								icon: true
							});
						}
					await this.getAllCourses();

				})
				.catch((error:any)=>{
					toast.info(' Cannot Delete', {
								timeout: 1000,
								closeOnClick: true,
								closeButton: 'button',
								icon: true
							});
					
				})
		})
		// `${this.datacourse[0].courseName}`
		this.routesArray=[
			{name:'Manage Courses',routeName:'course_management'},
			{name:this.datacourse[0].courseName,routeName:'course_details'},
			

		]
		// this.teamsDataArray.map((obj:any)=>{
		// 	console.log("teamdatarray:",obj);
		// 	if(obj.type==20302){
		// 		let myObj:any = {value:obj._id,label:obj.teamName};
		// 		console.log("myobjj:",myObj);
		// 		this.entitysTeams.push(myObj)
		// 	}
		// })

		// this.globalTeamsDataArray.map((mainObj:any)=>{
		// 	let globalObj:any= {value:mainObj._id,label:mainObj.teamName};
		// 	this.globalTeams.push(globalObj)
		// })
	}    
})
</script>
