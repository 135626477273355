<template>
	<div class="maincontainer" :class="{ ' h-screen flex flex-col justify-center items-center': !propData }">
		<div class=" card screenCard flex flex-row h-full">
			<div class="p-6 font-opensans w-3/5">
				<p class="titleheader text-2xl text-black font-bold">
					{{ templatePayload.headerLabel }}
				</p>
				<p class="mt-1 text-sm">{{templatePayload.headerData}}</p>
				<div class="optionsblock font-opensans">
					<h4 class="optiontitleheader titleheader text-base mt-4 text-black">
						{{ templatePayload.optionsLabel }}
					</h4>
					<div class="mt-4">
						<div
						class="inputGroup p-2  mt-2 rounded-md font-normal flex  text-base"
						:class="{
							correct: item.isRight,
							incorrect: item.isWrong
						}"
						v-for="(item, index) in templatePayload.options"
						:key="index"
					>
						<input type="checkbox" class="bg-white checkbox mt-1" :value="item.label" :id="item.label" v-model="checkedoptions" />
						<label :for="item.label" class="question text-black text-sm ml-2 w-full text-left">{{ item.label }}</label>
					</div>
					</div>
					<div class="flex justify-center mt-10">
						<button class="cursor-pointer font-normal py-1.5 px-3 text-base rounded text-center font-roboto" @click="onReset(templatePayload.options)" :class="checkedoptions.length > 0 ?  'btn-outline' : 'btn-outline-disabled'">Reset</button>
						<button class="ml-6 py-1.5 px-3" @click="onSave(templatePayload.options)" :class="checkedoptions.length > 0 && isDisable==false ?  'btnprimary' : 'btndisabled'">Submit</button>
					</div>
				</div>
			</div>
			<div class="w-2/5 bg-cover bg-no-repeat bg-center" :style="backgroundImages(templatePayload)"></div>
		</div>
		<div >
		<audio ref="player" preload="metadata" @loadedmetadata="controlEvents('start',$event)" @timeupdate="controlEvents('time',$event)" @ended="controlEvents('ended',$event)" >
			<source :src="link" type="audio/mp3" />
		</audio>
		<audio-video-control-bar :control="controlBarEvent" :type="true"> </audio-video-control-bar>
		</div>
		
	</div>
</template>

<style scoped>
.p-6{
	overflow-y: auto;
}
.text-sm{
	font-size: 14px;
line-height: 25px;
}
.btn-outline {
	border: 1px solid #000000;
	border-radius: 4px;
}
.btn-outline:hover {
	background-color: white;
	border: 1px solid #000000;
	border-radius: 4px;
	color: black;
}

.screenCard {
	height: 430px;
	width: 940px;
	background-size: 100% 100%;
	border-radius: 4px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}
.btn-outline-disabled{
	border: 1px solid #000000;
	border-radius: 4px;
	opacity: 0.5;
}
@media only screen and (max-width: 1280px) {
	.screenCard {
		height: 380px;
		width: 940px;
		background-size: 100% 100%;
		border-radius: 4px;
	}
}
@media only screen and (max-width: 1024px) {
	.screenCard {
		height: 325px;
		width: 680px;
		background-size: 100% 100%;
		border-radius: 4px;
	}
}
@media only screen and (max-width: 768px) {
	.screenCard {
		height: 300px;
		width: 500px;
		background-size: 100% 100%;
		border-radius: 4px;
	}
}
/* .btndisabled{
background: rgba(21, 42, 67, 0.5);
border-radius: 4px;
} */
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { globalApiMixin } from '../GlobalMixins/apiGlobalMixins';
import audioVideoControlBar from "@/components/audioVideoControlBar.vue";

export default defineComponent({
	props: ['propData'],
	mixins: [globalApiMixin],
	data(): any {
		return {
			dataPopulated: false,
			templatePayload: {},
			isValidation: false,
			checkedoptions: [],
			showResetbutton: true,

			link:'',
			controlBarEvent:'',
			screenType:'',
			isDisable:false
		};
	},
	components:{
		audioVideoControlBar
	},
	async mounted() {
		await this.fetchData();
		this.onReset();
	},
	methods: {
		controlEvents(status:any,e:any){
			if (status == 'start') {
					this.controlBarEvent = {'start':e};
			} else if (status == 'time') {
					this.controlBarEvent = {'time':e};
			} else {
					this.controlBarEvent = {'ended':'ended'};
			}
		},
		backgroundImages(payload: any) {
			if (payload.imageUrl != '') {
				return {
					backgroundImage: `url("${payload.imageUrl}")`
				};
			} else {
				return {
					backgroundImage: `url("${process.env.VUE_APP_COMMON_IMAGE}")`
				};
			}
		},
		onSave(options: any) {
			console.log("dddd",options)
			this.isDisable = true;
			for (var i = 0; i < options.length; i++) {
				for (var j = 0; j < this.checkedoptions.length; j++) {
					if (options[i].isCorrect == true) {
						options[i].isRight = true;
						
					}
					if (options[i].isCorrect == false) {
						options[i].isWrong = true;
					}
				}
			}
		},
		onReset() {
			this.checkedoptions = [];
			for (var i = 0; i < this.templatePayload.options.length; i++) {
				this.templatePayload.options[i].isRight = false;
				this.templatePayload.options[i].isWrong = false;
			}
		},

		async fetchData() {
			this.dataPopulated = false;
			if (this.propData) {
				this.templatePayload = this.propData;
				this.screenType = this.propData.screenType;
				console.log("this.propData",this.propData)
				this.link = this.templatePayload.audioUrl;
				this.dataPopulated = true;
			} else {
				await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/screen/${this.$route.params.screenId}`).then((response: any) => {
					this.templatePayload = response.data[0].data;
					this.link = this.templatePayload.audioUrl;
					this.screenType = response.data[0].screenType;
					console.log("this.screenType",this.screenType)
					var listOfOptions = response.data[0].data;
					for (var i = 0; i < listOfOptions.options.length; i++) {
						listOfOptions.options[i].isRight = false;
						listOfOptions.options[i].isWrong = false;
					}
					this.dataPopulated = true;
				});
			}
			this.$refs.player.load();
		}
	}
});
</script>
