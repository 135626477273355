<template>
	<div class="maincontainer w-full pt-3 pl-5 pr-5">
		<h1>Dashboard</h1>
		<div class="mt-5" v-if="dashboardData.status">
				<div class="lg:flex justify-between mt-5">
					<div class="sm:mb-2 lg:mb-0 mr-3 flex justify-center items-center cursor-pointer  h-17 rounded w-full border">
						<div class="py-1">
							<p class="text-sm font-normal px-2">Active Users</p>
							<p class="text-center mt-1 font-bold text-lg">{{dashboardData.status.inprogress}}</p>
						</div>
					</div>
					<div  class="mr-3 sm:mb-2 lg:mb-0  flex relative justify-center items-center indicateBoxes h-17 rounded w-full borderlightgreen  bg-opacity-green">
						<div class="w-full h-full absolute rounded" ></div>
						<div class="flex rounded items-center regular text-sm">
							<div class="py-1">
								<p class="text-sm font-normal text-center">Completed</p>
								<p class="mt-1 text-center font-bold text-lg">{{ dashboardData.status.completed }}</p>
							</div>
						</div>
					</div>
					<div  class="mr-3 sm:mb-2 lg:mb-0  flex relative justify-center items-center indicateBoxes h-17 rounded w-full borderProgress bg-opacity-progress">
						<div class="w-full h-full absolute rounded" ></div>
						<div class="flex rounded items-center regular text-sm">
							<div class="py-1">
								<p class="text-sm font-normal text-center">In Progress</p>
								<p class="mt-1 text-center font-bold text-lg">{{dashboardData.status.inprogress}}</p>
							</div>
						</div>
					</div>
					<div  class="mr-3 sm:mb-2 lg:mb-0  flex relative justify-center items-center indicateBoxes h-17 rounded w-full borderGrey bg-opacity-notstarted">
						<div class="w-full h-full absolute rounded" ></div>
						<div class="flex rounded items-center regular text-sm">
							<div class="py-1">
								<p class="text-sm font-normal text-center">Not Started</p>
								<p class="mt-1 text-center font-bold text-lg">{{ dashboardData.status.notstarted }}</p>
							</div>
						</div>
					</div>
					<div  class="mr-3 sm:mb-2 lg:mb-0  flex relative justify-center items-center indicateBoxes h-17 rounded w-full borderOverdue  bg-opacity-overdue">
						<div class="w-full h-full absolute rounded" ></div>
						<div class="flex rounded items-center regular text-sm">
							<div class="py-1">
								<p class="text-sm font-normal text-center">Overdue</p>
								<p class="mt-1 text-center font-bold text-lg">{{ dashboardData.status.overdue }}</p>
							</div>
						</div>
					</div>
					<div  class="mr-3 sm:mb-2 lg:mb-0  flex relative justify-center items-center indicateBoxes h-17 rounded w-full borderPrimary bg-opacity-activecourses">
						<div class="w-full h-full absolute rounded" ></div>
						<div class="flex rounded items-center regular text-sm">
							<div class="py-1">
								<p class="text-sm font-normal text-center">Active Courses</p>
								<p class="mt-1 text-center font-bold text-lg">{{ dashboardData.totalCourses }}</p>
							</div>
						</div>
					</div>
				</div>
			<div class="bg-white mt-8 p-5 gap-4 rounded-sm border border-lightgrey">
				<div class="flex justify-between">
					<div class="flex items-center"><h1 class="text-primary text-base font-semibold">Status of All Courses</h1></div>
					<div class="export-section flex flex-row-reverse cursor-pointer">
						<button class="btnprimary" @click="generateAllCoursesCsv()">Export</button>
					</div>
				</div>
				<div class="w-full mt-3">
					<div class="card flex justify-center min-h-170 p-2">
						<div id="chart">
							<apexchart ref="chart" type="bar" height="470" :options="chartOptionsForStackedChart" :series="stackBarChart"></apexchart>
						</div>
					</div>
				</div>
			</div>
			<div class="flex justify-between bg-white items-center mt-8 p-5 gap-4 rounded-sm border border-lightgrey">
				<div class="w-6/12">
					<div class="card min-h-170 chart p-4 flex">
						<div class="flex justify-between">
							<h1 class="text-primary text-base font-semibold">Status of Course</h1>
							<button class="btnprimary" v-if="selectedCourse" @click="generateCsv()">Export</button>
						</div>
						<div class="flex mt-3">
							<div class="w-full">
								<select v-model="selectedCourse" class="inputboxstyling" @change="generateGraph()">
									<option disabled hidden value="">Select a Course</option>
									<option v-for="course in allCourses" :value="course.masterCourseId" :key="course.masterCourseId" :class="course.courseName.length < 15 ? '' : text - sm">{{ course.courseName }}</option>
								</select>
							</div>
						</div>
						<div class="p-2" v-if="showGraph">
							<apexchart width="400" type="donut" :options="chartOptions" :series="series"></apexchart>
						</div>
					</div>
				</div>
				<div class="w-6/12 card chart min-h-170 bg-white rounded p-4">
					<h1 class="text-primary text-base font-semibold">Feedback</h1>
					<div class="card mt-3 p-3 flex overflow-auto">
						<div v-for="(object, index) in courseFeedBackInfo" :key="object">
							<div class="flex items-center justify-between p-3">
								<div class="text-primary text-sm font-semibold w-3/6">
									{{
										allCourses.find((obj) => {
											return obj.masterCourseId == object._id;
										}).courseName
									}}
								</div>
								<div class="w-3/6 flex justify-end mr-1">
									<svg style="display: none">
										<defs>
											<symbol id="fivestars">
												<path
													id="Star New"
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M120 0H0V24H120V0ZM14.125 9.98047L12 3.44141L9.875 9.98047H3L8.5625 14.0195L6.4375 20.5586L12 16.5176L17.5625 20.5605L15.4375 14.0195L21 9.98047H14.125ZM38.125 9.98047L36 3.44141L33.875 9.98047H27L32.5625 14.0195L30.4375 20.5586L36 16.5176L41.5625 20.5605L39.4375 14.0195L45 9.98047H38.125ZM60 3.44141L62.125 9.98047H69L63.4375 14.0195L65.5625 20.5605L60 16.5176L54.4375 20.5586L56.5625 14.0195L51 9.98047H57.875L60 3.44141ZM86.125 9.98047L84 3.44141L81.875 9.98047H75L80.5625 14.0195L78.4375 20.5586L84 16.5176L89.5625 20.5605L87.4375 14.0195L93 9.98047H86.125ZM108 3.44141L110.125 9.98047H117L111.438 14.0195L113.562 20.5605L108 16.5176L102.438 20.5586L104.562 14.0195L99 9.98047H105.875L108 3.44141Z"
													fill="white"
												/>
											</symbol>
										</defs>
									</svg>
									<div class="rating">
										<progress class="rating-bg" :value="averageRating(object)" :max="5"></progress>
										<svg><use xlink:href="#fivestars" /></svg>
									</div>
								</div>
								<div class="text-primary text-sm font-semibold pl-4 mt-1" :class="averageRating(object) == 0 ? 'pl-7' : 'pl-4'">
									{{ averageRating(object) }}
								</div>
							</div>
							<div class="border border-lightgrey" v-if="index != courseFeedBackInfo.length - 1"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
.vue-apexcharts {
	padding-right: 10px !important;
	padding-left: 10px !important;
}
.borderlightgreen{
	border: 1px solid #00B050;
	color:#00B050;
}
.bg-opacity-green {
    background-color: rgba(0, 176, 80, 0.2);
}
.borderProgress{
	border:1px solid #FFC000;
	color: #FFC000;
}
.bg-opacity-progress{
	background-color: rgba(255,192,0,0.2);	
}
.borderGrey{
	border:1px solid #4D4D4D;
	color:#4D4D4D;
}
.bg-opacity-notstarted{
	background-color: rgba(77,77,77,0.2);
}
.borderOverdue{
	border:1px solid #FF0000;
	color: #FF0000;
}
.bg-opacity-overdue{
	background-color: rgba(255,0,0,0.2);
}
.borderPrimary{
	border:1px solid #152a43;
	color: #152a43;
}
.bg-opacity-activecourses{
	background-color: rgba(21, 42, 67, 0.2);

}
select {
	text-overflow: ellipsis;
	background-position: right 8px center;
}
.chart {
	height: 460px;
}
.rating {
	width: 120px;
	height: 24px;
	position: relative;
	background-color: #ffffff;
}
.rating progress.rating-bg {
	display: inline-block;
	height: 20px;
	width: 100%;
}
.rating progress.rating-bg::-webkit-progress-value {
	background-color: #152a43;
}
.rating progress.rating-bg::-webkit-progress-bar {
	background-color: #e9e9e9;
}
.rating svg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 110%;
}
::-webkit-scrollbar {
	width: 2px;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import VueApexCharts from 'vue3-apexcharts';
import { mapGetters } from 'vuex';
export default defineComponent({
	components: {
		apexchart: VueApexCharts
	},
	data(): any {
		return {
			allCourses: [],
			stackBarChart: [],
			selectedCourse: '',
			showGraph: false,
			series: [],
			stackedCategories: [],
			courseFeedBackInfo: [],
			chartOptions: {
				colors: ['#00B050', '#FFC000', '#4D4D4D', '#FF0000'],
				labels: ['Completed', 'In Progress', 'Not Started', 'Overdue'],
				legend: {
					fontSize: '14px',
					// horizontalAlign:'center',
					position: 'bottom'
				}
			},
			chartOptionsForStackedChart: {
				colors:["#FFC000","#FF0000","#4D4D4D","#00B050"],
				chart: {
					stacked: true,
					toolbar: {
						show: false
					},
					stackType: '100%',
				},
					
				
			plotOptions: {
					bar: {
						horizontal: true,
						
						
					
					}	
				},
		
					
		
				yaxis: {
					labels: {
						// show: true,
						minWidth: 10,
						maxWidth:350
					}  
				},
  
				xaxis: {
					//   type: 'category',
					categories: [],
					labels: {
						show: true
					}
				}
			},

			dashboardData: {}
		};
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' })
	},
	methods: {
		async getAllCourses() {
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/courses`).then((res: any) => {
				res.data.map((object: any) => {
					if (Object.keys(object).includes('courseExpiry')) {
						this.allCourses.push(object);
					}
				});
				// this.allCourses = res.data;
			});
		},
		async generateDashboardData() {
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/dashboardcoursesData`).then((res: any) => {
				this.dashboardData = res.data;
				// console.log('ssss', this.dashboardData.status.inprogress);
			});
		},
		async generateFeedbackData() {
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/feedback`).then((res: any) => {
				this.courseFeedBackInfo = res.data;
			});
		},
		averageRating(object: any) {
			let ratingArray = [object.rating5, object.rating4, object.rating3, object.rating2, object.rating1];

			const sum = ratingArray.reduce((acc, rating, index) => acc + rating * (5 - index), 0);
			const totalRatings = ratingArray.reduce((acc, rating) => acc + rating, 0);

			if (totalRatings === 0) {
				return 0.0;
			} else {
				let averageRatingOfCourse = sum / totalRatings;
				return averageRatingOfCourse.toFixed(1);
			}
		},
		removeKeys(obj: any, keys: any) {
			const newObj = { ...obj };
			keys.forEach((key: any) => {
				if (Object.keys(newObj).includes(key)) {
					delete newObj[key];
				}
			});
			return newObj;
		},
		async generateAllCoursesCsv() {
			let payload = {
				courseId: this.selectedCourse
			};
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/exportallcoursesCsv`, payload).then((res: any) => {
				let csv = '';
				let items: any = [];
				let deleteKeys = ['modifiedAt', 'examStatus'];
				res.data.map((obj: any) => {
					let a = this.removeKeys(obj, deleteKeys);
					items.push(a);
				});
				console.log('itemsitemsitemsitemsitemsitems', items);
				for (let row = 0; row < items.length; row++) {
					let keysAmount = Object.keys(items[row]).length;
					let keysCounter = 0;
					if (row === 0) {
						for (let key in items[row]) {
							if (key == 'statusCodeId') {
								csv += 'status' + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
							} else if (key == 'userid') {
								csv += 'userId' + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
							} else {
								csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
							}
							keysCounter++;
						}
						keysCounter = 0;
					}
					for (let key in items[row]) {
						if (key == 'statusCodeId') {
							switch (items[row][key]) {
								case 10401:
									items[row][key] = 'Not Started';
									break;
								case 10402:
									items[row][key] = 'In Progress';
									break;
								case 10403:
									items[row][key] = 'Completed';
									break;
								case 10404:
									items[row][key] = 'Overdue';
									break;
								default:
									console.log(`error ${items[row][key]}.`);
							}
						}
						csv += items[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
						keysCounter++;
					}

					keysCounter = 0;
				}
				let link = document.createElement('a');
				link.id = 'download-csv';
				link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
				link.setAttribute('download', `DynematrixAllCourses.csv`);
				document.body.appendChild(link);
				link.click();
			});
		},
		async generateCsv() {
			console.log('selected course', this.selectedCourse);
			let payload = {
				courseId: this.selectedCourse
			};
			await this.$http.post(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/exportCsv`, payload).then((res: any) => {
				let csv = '';
				let items: any = [];
				let deleteKeys = ['modifiedAt', 'examStatus'];
				res.data.map((obj: any) => {
					let a = this.removeKeys(obj, deleteKeys);
					items.push(a);
				});

				// Loop the array of objects
				for (let row = 0; row < items.length; row++) {
					let keysAmount = Object.keys(items[row]).length;
					let keysCounter = 0;
					if (row === 0) {
						for (let key in items[row]) {
							if (key == 'statusCodeId') {
								csv += 'status' + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
							} else if (key == 'userid') {
								csv += 'userId' + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
							} else {
								csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
							}
							keysCounter++;
						}
						keysCounter = 0;
					}
					for (let key in items[row]) {
						if (key == 'statusCodeId') {
							switch (items[row][key]) {
								case 10401:
									items[row][key] = 'Not Started';
									break;
								case 10402:
									items[row][key] = 'In Progress';
									break;
								case 10403:
									items[row][key] = 'Completed';
									break;
								case 10404:
									items[row][key] = 'Overdue';
									break;
								default:
									console.log(`error ${items[row][key]}.`);
							}
						}
						csv += items[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
						keysCounter++;
					}

					keysCounter = 0;
				}
				let link = document.createElement('a');
				link.id = 'download-csv';
				link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
				link.setAttribute('download', `Dynematrix${this.selectedCourse}.csv`);
				document.body.appendChild(link);
				link.click();
			});
		},
		async generateGraph() {
			console.log('selected course', this.selectedCourse);
			let payload = {
				courseId: this.selectedCourse
			};
			await this.$http.post(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/dashboard`, payload).then((res: any) => {
				console.log('dahsboard data', res.data);
				let data = res.data[0];
				delete data._id;
				this.series = [];
				this.series.push(data['Completed'], data['In progress'], data['Not started'], data['Overdue']);
				// this.series = Object.values(data);
				console.log('this.series', this.series);
				this.showGraph = true;
			});
		},
		async generateStackedBar() {
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/generateStackBarChart`).then((res: any) => {
				let data =res.data

				// for(let i=0;i<5;i++){
				// 	data.push(...res.data)
				// }
				let Inprogress = [];
				let Notstarted = [];
				let Completed = [];
				let Overdue = [];
				let obj = [];

				for (var i = 0; i < data.length; i++) {
					console.log('chart', data[i]);
					this.stackedCategories.push(data[i]['_id']);
					console.log('stacked', this.stackedCategories);
					if (Object.keys(data[i].Inprogress)) {
						Inprogress.push(data[i].Inprogress);
					}
					if (Object.keys(data[i].Notstarted)) {
						Notstarted.push(data[i].Notstarted);
					}
					if (Object.keys(data[i].Completed)) {
						Completed.push(data[i].Completed);
					}
					if (Object.keys(data[i].Overdue)) {
						Overdue.push(data[i].Overdue);
					}
				}
				console.log("stackedCategories",this.stackedCategories)

					const updatedOptions = {
						chart: {
							height: 50 * this.stackedCategories.length // Ensure this is correctly calculated
						},
						xaxis: {
							categories: this.stackedCategories
						}
						};
						this.$refs.chart.updateOptions(updatedOptions);
										this.stackBarChart = [
					{
						name: 'In Progress',
						data: Inprogress
					},
					{
						name: 'Overdue',
						data: Overdue
					},
					{
						name: 'Not Started',
						data: Notstarted
					},
					{
						name: 'Completed',
						data: Completed
					}
				];
			});
		}
	
	},

	async mounted() {
		await this.getAllCourses();
		await this.generateDashboardData();
		await this.generateStackedBar();
		await this.generateFeedbackData();
	}
});
</script>
