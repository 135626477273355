<template>
	<div class="maincontainer pt-3 px-5">
		<div class="headercontainer flex justify-between">
			<div v-if="allCourses.length !== 0">
				<h1 class="font-bold">Courses</h1>
			</div>
			<!-- <div> -->
				<div class="buttonposition">
					<router-link :to="{ name: 'add-course' }">
						<button class="btnprimary">Add Course</button>
					</router-link>
				</div>
			<!-- </div> -->
		</div>
		<div class="flex  flex-wrap gap-2" v-if="allCourses.length">
			<div v-for="course in allCourses" :key="course._id" class="mt-2">
				<div class="card  cursor-pointer rounded" @click="onedit(course)">
					<div class="h-1/2 ">
						<figure>
							<img v-if="course.courseImageUrl" :src="course.courseImageUrl" class="flex justify-center items-center rounded-t" />
							<div v-else class="bg-primary flex justify-center items-center p-1 rounded-t">
							<h1 class="text-white text-lg" :class="course.courseName.length > 40? 'table-ellipsis':''">{{ course.courseName}}</h1>
						</div>
						</figure>
					</div>
					<div class="card-body p-4 h-1/2">
						<h1 :class="course.courseName.length > 40? 'table-ellipsis':''">
							{{ course.courseName }}
						</h1>
						<p class=" overflow-ellipsis  text-justify ">{{ course.courseDescription }}</p>
					</div>
				</div>
			</div>
		</div>
		<div class="mt-8 text-sm text-center" v-else>No courses. Please create one</div>
	</div>
</template>

<style scoped>
p{
	font-size: 12px !important;
	line-height: 18px;
}
.overflow {
	-webkit-box-orient: vertical;
	display: -webkit-box;
	-webkit-line-clamp: 8;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
}
.m-4::after{
	content: "";
    flex: auto;
    flex-basis: 320px;
    flex-grow: 0;
}
.card{
	   height: 380px;
    width: 320px;
}
.table-ellipsis {
  display:-webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
	data(): any {
		return {
			allCourses: [],
			defaultImageUrl: 'https://picsum.photos/id/237/200/300',
			courseLength: 0
		};
	},
	methods: {
		getAllCourses() {
			this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/courses/getAll`).then((res: any) => {
				this.allCourses = res.data;
			});
		},
		onedit(course: any): any {
			this.$router.push({ name: 'edit-course', params: { id: course._id } });
		}
	},
	async mounted() {
		await this.getAllCourses();
	}
});
</script>