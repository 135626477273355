import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-dab7286e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card screenCard flex flex-row h-full" }
const _hoisted_2 = { class: "p-6 font-opensans w-3/5" }
const _hoisted_3 = { class: "titleheader text-2xl text-black font-bold" }
const _hoisted_4 = { class: "mt-1 text-sm" }
const _hoisted_5 = { class: "optionsblock font-opensans" }
const _hoisted_6 = { class: "optiontitleheader titleheader text-base mt-4 text-black" }
const _hoisted_7 = { class: "mt-4" }
const _hoisted_8 = ["value", "id"]
const _hoisted_9 = ["for"]
const _hoisted_10 = { class: "flex justify-center mt-10" }
const _hoisted_11 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_audio_video_control_bar = _resolveComponent("audio-video-control-bar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["maincontainer", { ' h-screen flex flex-col justify-center items-center': !_ctx.propData }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.templatePayload.headerLabel), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.templatePayload.headerData), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h4", _hoisted_6, _toDisplayString(_ctx.templatePayload.optionsLabel), 1),
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templatePayload.options, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["inputGroup p-2 mt-2 rounded-md font-normal flex text-base", {
							correct: item.isRight,
							incorrect: item.isWrong
						}]),
                key: index
              }, [
                _withDirectives(_createElementVNode("input", {
                  type: "radio",
                  value: item.label,
                  id: item.label,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkedoptions) = $event)),
                  class: "radio mt-1"
                }, null, 8, _hoisted_8), [
                  [_vModelRadio, _ctx.checkedoptions]
                ]),
                _createElementVNode("label", {
                  for: item.label,
                  class: "question text-black text-sm ml-2 w-full text-left"
                }, _toDisplayString(item.label), 9, _hoisted_9)
              ], 2))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("button", {
              class: _normalizeClass(["cursor-pointer font-normal py-1.5 px-3 text-base rounded text-center font-roboto", _ctx.checkedoptions.length > 0 ?  'btn-outline' : 'btn-outline-disabled']),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onReset(_ctx.templatePayload.options)))
            }, "Reset", 2),
            _createElementVNode("button", {
              class: _normalizeClass(["ml-6 py-1.5 px-3", _ctx.isDisable==false &&  this.checkedoptions.length>0 ?  'btnprimary' : 'btndisabled']),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSave(_ctx.templatePayload.options)))
            }, "Submit", 2)
          ])
        ])
      ]),
      _createElementVNode("div", {
        class: "w-2/5 bg-cover bg-no-repeat bg-center",
        style: _normalizeStyle(_ctx.backgroundImages(_ctx.templatePayload))
      }, null, 4)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("audio", {
        ref: "player",
        preload: "metadata",
        onLoadedmetadata: _cache[3] || (_cache[3] = ($event: any) => (_ctx.controlEvents('start',$event))),
        onTimeupdate: _cache[4] || (_cache[4] = ($event: any) => (_ctx.controlEvents('time',$event))),
        onEnded: _cache[5] || (_cache[5] = ($event: any) => (_ctx.controlEvents('ended',$event)))
      }, [
        _createElementVNode("source", {
          src: _ctx.link,
          type: "audio/mp3"
        }, null, 8, _hoisted_11)
      ], 544),
      _createVNode(_component_audio_video_control_bar, {
        control: _ctx.controlBarEvent,
        type: true
      }, null, 8, ["control"])
    ])
  ], 2))
}