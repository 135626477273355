<template>
	<div >
		<div class="navbar bg-white sticky z-50 top-0 left-0 flex shadow p-2" >
		<div class="navbar-start">
			<div class="w-40">
				<div class="lg:flex">
					<span class="text-lg font-bold cursor-pointer">
						<img src="@/assets/dynematrix.svg" alt="" class="w-96 h-8" />
					</span>
				</div>
			</div>
		</div>
		<div class="navbar-center">
			<div class="items-center justify-center lg:flex">
				<h1>{{ courseDetails.coursename }}</h1>
			</div>
		</div>
		<div class="navbar-end">
			<button class="btnprimary" @click="onExit()">EXIT</button>
		</div>
	</div>
		<div class="p-4">
			<div >
			<h1 class="font-bold">Course Modules</h1>
		</div>
		<div class="flex gap-2 flex-wrap mt-2">
			<div v-for="module in  modulesdata" :key="module.id" class="mt-2" @click="onedit(module)">
				<div class="card h-72 w-80 cursor-pointer rounded" :class="{ 'opacity-75 pointer-events-none': !(module.isActive || module.isCompleted) }">
				<div class="h-4/6">
					<figure>
						<div v-if="module.imageUrl" class="h-32 flex justify-center relative">
							<img  :src="module.imageUrl" class="card-bordered bg-cover bg-no-repeat bg-center  " />
							<!-- <h1 class="text-white a text-lg absolute">{{ module.moduleName}}</h1> -->
						</div>
						<div v-else class="bg-primary flex justify-center items-center t">
							<h1 class="text-white text-lg">{{ module.moduleName}}</h1>
						</div>
						<!-- <img v-else :src="defaultImageUrl" class="card-bordered bg-cover bg-no-repeat bg-center" /> -->
					</figure>
				</div>
					<div class="card-body p-4 h-2/6">
						<div class="flex">
									<div class="w-3/4">
										<p class=" mb-0 h-12">{{ module.moduleName }}</p>
									</div>
								
							<div class="w-1/4 flex flex-row-reverse mb-3.5">
								<img src="@/assets/correctCircleIcon.svg" v-if="module.isCompleted" class="w-8" />
							<img src="@/assets/pauseModule.svg" v-if="module.isActive" class="w-8" />
							<img src="@/assets/wrongCircleIcon.svg"  v-if="!(module.isActive || module.isCompleted)" class="w-8" />
							</div>
						</div>
						<progress class="progress progress-primary mt-2" :value="module.isActive || module.isCompleted ? (module.completedScreens / module.totalScreens) * 100 : 0" max="100"></progress>
					</div>
				</div>
			</div>
		</div>
		</div>
	</div>
</template>
<style scoped>
.a{
	  position: absolute;
  top: 50%;
  left: 50%;
   transform: translate(-50%, -50%);
}
.mt-4::after{
	content: "";
    flex: auto;
    flex-basis: 320px;
    flex-grow: 0;
}
.progress-primary::-webkit-progress-value {
    --tw-bg-opacity: 1;
    background-color: #152a43
}
.text-base{
	line-height: 1rem;
	font-size: 18px;
}
.navbar{
	min-height: 2.5rem;
	height:2.5rem
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { toast } from '../../main';
import { alphaNum } from '@vuelidate/validators';
export default defineComponent({
	data(): any {
		return {
			modules: [],
			courseComplete: 30,
			arrays: [],
			circumference: '',
			strokeDashoffset: '',
			showExamModuleStatus:false,
			courseDetails:[],
			finalModules: [],
			moduledata:[],
			defaultImageUrl: 'https://picsum.photos/id/1005/400/250'
		};
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
		modulesdata: function ():any{
			return this.modules.filter((module:any)=>{ return module.screens && module.isModuleActive == true || module.moduleName == 'Test'})
		}
	},
	methods: {
		 handleBeforeUnload() {
      // Notify the parent window
      window.opener.postMessage('pageClosed', '*');
   		 },
		onedit(module: any): any {
			if (module.moduleId == '11111') {
				let modulesWithoutTest = this.modulesdata.filter((obj:any)=>{ return (obj.moduleName != 'Test')});
				let examCondition = modulesWithoutTest.every((module:any)=>{return module.isCompleted == true});
				console.log('oneoneoneoneone',modulesWithoutTest)
				console.log('examConditionexamCondition',examCondition)

				if(examCondition == true){
					this.goToTest();
				}else{
					toast.error('Modules are in pending', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				}
			} else {
				this.$router.push({ name: 'screens', params: { courseId: this.$route.params.courseId, moduleId: module._id, activeScreen: module.isCompleted ? 0 : module.completedScreens ? module.completedScreens : 0 } });
			}

			// window.open(routeData.href, '_blank');
		},
		onExit(){
			window.close()
		},
		async getUserActivityModules() {
			this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/user/${this.userInfo._id}/course/${this.$route.params.courseId}/userActivity`).then((res: any) => {
				this.modules = res.data;
			});
		},
		goToTest() {
			this.$router.push({ name: 'test-screen', params: { courseId: this.$route.params.courseId } });
			// var closedroute: any = window.open(routeData.href, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes');
		}
	},
	async created() {
		await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/modules/getAll`).then(async(res: any) => {
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/user/${this.userInfo._id}/course/${this.$route.params.courseId}/userActivity`).then((a: any) => {
				let userActivityModules = a.data[0];
				this.courseDetails =a.data[0];
				if(a.data[0].examStatus){
					a.data[0].examStatus.totalModules=a.data[0].examStatus ? a.data[0].examStatus.totalModules+1:a.data[0].examStatus.totalModules+0
					var resultPercentage = Math.round((a.data[0].examStatus.pass / a.data[0].examStatus.total) * 100);
					if (resultPercentage < 80) {
						 this.showExamModuleStatus =false
						} else {
							this.showExamModuleStatus =true;
						}
					
				}else{
					this.showExamModuleStatus =false;
				}
				if (userActivityModules && userActivityModules.modules) {
					this.modules = res.data.map((module: any, index: number) => {
						let filteredModule = userActivityModules.modules.find((aModule: any) => {
							return aModule.moduleId == module._id;
						});
						if (filteredModule) {
							module.isActive = filteredModule.statusCodeId == 10402;
							module.isCompleted = filteredModule.statusCodeId == 10403;
							module.totalScreens = filteredModule.totalScreens;
							module.completedScreens = filteredModule.completedScreens;
						} else {
							module.isActive = false;
							module.isCompleted = false;
							module.totalScreens = 0;
							module.completedScreens = 0;
						}
						if (index != 0) {
							if (res.data[index - 1].isCompleted && !module.isCompleted) module.isActive = true;
						}
						return module;
					});
					let showExamModule =this.modules
					this.modules =	this.modules.filter((module:any)=> module.screens && module.isModuleActive == true );
					let filteredModules = [...this.modules]
					let exam = filteredModules.some((module:any)=>{
						return  module.screens && module.isModuleActive==true && module.questionaire;
					})
					if(exam){
						this.modules.push({
						moduleName: 'Test',
						isCompleted:this.showExamModuleStatus,
						moduleId:'11111',
						completedScreens:100,
						totalScreens:100,
						createdBy: '',
						createdAt: '',
						updatedBy: '',
						updatedAt: '',
						screens:['1334556577']
					});
					}		
				} else {
					this.modules = res.data.map((v: any) => ({ ...v, isActive: false, isCompleted: false }));
					let showExamModule =this.modules
					this.modules =	this.modules.filter((module:any)=> module.screens && module.isModuleActive == true )
					let filteredModules = [...this.modules];
					let exam = filteredModules.some((module:any)=>{
						return  module.screens && module.isModuleActive == true && module.questionaire;
					})
					if(exam){
						this.modules.push({
						moduleName: 'Test',
						moduleId:'11111',
						isCompleted:this.showExamModuleStatus,
						completedScreens:100,
						totalScreens:100,
						createdBy: '',
						createdAt: '',
						updatedBy: '',
						updatedAt: '',
						screens:['1334556577']
					});
					}
					// 	console.log("sjhsh")
					// this.modules.forEach((module: any) => {
					// 	console.log("module",module);
					// 	if (module.screens) {
					// 		this.finalModules.push(module);
					// 	}
					// });
					// this.modules.push({
					// 	moduleName: 'Start Exam',
					// 	moduleId:'11111',
					// 	createdBy: '',
					// 	createdAt: '',
					// 	updatedBy: '',
					// 	updatedAt: ''
					// });
					// console.log('aasss', this.finalModules);
				}
			});
		});
	},
	 	beforeUnmount() {
    // Clean up the event listener when the component is about to be unmounted
   		 window.removeEventListener('beforeunload', this.handleBeforeUnload);
  		},

		mounted(){
		// Listen for the 'beforeunload' event on Page B
		 window.addEventListener('beforeunload', this.handleBeforeUnload);
	}
});
</script>