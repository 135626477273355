<template>
    <div v-if="dotsLoading" class="mt-2">
		<div class="flex items-center justify-center w-full h-full skeleton mt-4 tableCornerRadius bg-white">
			<dots-loader />
		</div>
	</div>	

    <div class=" overflow-x-auto tableCornerRadius bg-white" v-else>
        <table>
            <thead>
                <!-- /* Only for headers in table */ -->
                <th scope="col" v-for="presentColumn in columnNames" :key="presentColumn" class="bg-white capitalize" @click="sort(presentColumn)" :class="headerAlignment(presentColumn)">
                    <div class="flex" >
                        <div>{{getcolumnLabel(presentColumn)}}</div>
                        <div class="sort cursor-pointer" :class="className == presentColumn ? classSort : 'opacity-50'" v-if="getcolumnLabel(presentColumn)!==''"></div>
                    </div>
                </th>
                        <!-- filters for component -->
                <tr v-if="visibility">
                    <!-- <div :class="getFilterPosition(presentColumn)"> -->
                    <td v-for="(presentColumn, index) in columnNames" class="bg-white" :key="index">
                         <select v-if="isColumnArrayPresent(presentColumn)" class="inputboxStylingForFilter filter-font-size" v-model="filters[presentColumn]" :class="getFilterSize(presentColumn)">
                            <option :value="filters.presentColumn" selected hidden disabled>Select</option>
                            <option v-for="option in getDropdownOptionsForFilter(presentColumn)" :key="option.value" :value="option.value">{{ option.label }}</option>
                        </select>
                        <div v-else-if="isFilterNotNecessary(presentColumn)"></div>
                        <input v-else type="text" class="inputboxstyling filter-font-size" v-model="filters[presentColumn]" placeholder="Search" :v-if="presentColumn!=='deleteIcon'"/>
                    </td>
                    <!-- </div> -->
                </tr>
            </thead>
            <tbody v-if="sortedData.length == 0">
                <tr class="cursor-pointer rowHeight">
                    <td class="relative h-10" :colspan="columnNames.size">
                        <div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
                    </td>
                </tr>
            </tbody>
            <tbody class="nonEmptyTable" v-else>
                <tr v-for="(row, index) in sortedData" :key="row" class="cursor-pointer rowHeight" >
                    <td v-for="presentColumn in columnNames" :key="presentColumn" @mouseout="doFalse()" @click="actionOnClickingTableRow(row, index, presentColumn)" class="cursor-pointer relative">
                        <div>
                            <div v-if="isColumnArrayPresent(presentColumn)">
                                    <div class="text-xs">
                                        {{getColumnRealtedValueFromArray(row[presentColumn],presentColumn)}}
                                    </div>
                            </div>
                            <div v-else-if="presentColumn=='deleteIcon'">
                                 <div class="flex justify-start items-center h-5 w-5 ml-6">
                                    <img src="@/assets/deleteIcon.svg" class="h-5 w-5 cursor-pointer"/>
                                </div>
                            </div>
                             <div v-else-if="presentColumn=='userCount'">
                                <div class="pl-10" @mousemove="toolTip(presentColumn, $event, index, row, 'text')">
                                    {{ row[presentColumn] }}
                                </div> 
                            </div>
                           
                            <div v-else-if="presentColumn=='dueDays'">
                                <div class="pl-6" @mousemove="toolTip(presentColumn, $event, index, row, 'text')">
                                    {{ row[presentColumn] }}
                                </div> 
                            </div>
                             <div v-else-if="presentColumn=='courseExpiry'">
                                <div class="pl-3" @mousemove="toolTip(presentColumn, $event, index, row, 'text')">
                                    {{ row[presentColumn] }}
                                </div> 
                            </div>
                          
                            <div class="table-ellipsis" v-else>
                                <div @mousemove="toolTip(presentColumn, $event, index, row, 'text')">
                                    {{ row[presentColumn] }}
                                </div>
                            </div>
                            <div v-if="pop == true && sortedData[tipIndex][presentColumn] == tipText && tipColumn == presentColumn && tipIndex == index">
                                <tool-tip :columnName="presentColumn" :tipText="tipText" :styling="styling"></tool-tip>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedData"></pagination>
    </div>
</template>
<script lang="ts">
import { emitter } from "@/main";
import toolTip from '@/components/toolTip.vue';
import pagination from '@/components/pagination.vue';
import { defineComponent } from "vue";
import dotsLoader from '@/components/dotsLoader.vue';

export default defineComponent({
    props: ['tableRowData', 'headersData', 'openPopup', 'navigate'],
    data():any{
        return{
            tableRow: [],
            tableHead:[],
            className:'',
            visibility: false,
            classSort: '',
            currentSort: 'name',
            currentSortDir: 'asc',
            showDropDown: false,
            showBlock: false,

            currentPage: '',
            pageSize: '',
            dynamicPagination: '',
            
            pop: false,
            styling: { left: '', bottom: '' },
            tipText: '',
            tipColumn: '',
            tipIndex: '',
            filters: {},
            columns:[
                {key:'type',value:'Access'},
                {key:'entityId',value:'Entity Name'},
                {key:'courseTargetTypeCodeId',value:'Target Audience'},
                {key:'userCount',value:'No.of Audience'},
                {key:'courseTypeCodeId',value:'Course Type'},
                {key:'dueDays',value:'Duration'},
                {key:'courseExpiry',value:'Expiry'}
                
            ],
            dropdownOptions: [
				{
                    key:'type',
                    value:[
                        {
                            value: 20301,
                            label: 'Global'
                        },
                        {
                            value: 20302,
                            label: 'Region'
                        },
                        {
                            value: 20303,
                            label: 'Entity'
                        }
                    ]
                },
                {
                    key:'courseTargetTypeCodeId',
                    value: [
                            {
                                value: 10501,
                                label: 'EveryOne'
                            },
                            {
                                value: 10502,
                                label: 'Teams'
                            },
                            { 
                                value: 10503, 
                                label: 'Users' 
                            }
			            ],
                },
                  {
                    key:'courseTypeCodeId',
                    value: [
                            {
                                value: 10601,
                                label: 'Mandatory'
                            },
                            {
                                value: 10602,
                                label: 'Optional'
                            }
			            ],
                }
			],
        }
    },
    components: {
        toolTip,
        pagination,
        dotsLoader
    },
    async mounted() {
        this.initialize();
        //  await this.getAllEntities();
        emitter.on('visible', (isBoolean: any) => {
            this.visibility = isBoolean;
            this.visibility == false ? (this.filters = {}) : '';
        });
        // emitter.on('*', (type, booleanValue) => {
        // 	if (type == 'apps-sidemenubar' || type == 'toggle-sidebar') {
        // 		this.showDropDown == true ? (this.showDropDown = false) : '';
        // 	} else {
        // 		if (this.showBlock === true) {
        // 			this.showDropDown == true ? (this.showDropDown = booleanValue) : '';
        // 		} else if (this.showBlock === false) {
        // 			this.showDropDown == true ? '' : (this.showDropDown = booleanValue);
        // 			this.showBlock = true;
        // 		}
        // 	}
        // });
        emitter.on('*', (type, booleanValue) => {
            if (type === 'apps-sidemenubar' || type === 'toggle-sidebar') {
                if (this.showDropDown) {
                    this.showDropDown = false;
                }
                if (this.showTableActionsDropdown) {
                    this.showTableActionsDropdown = false;
                }
            } else {
                if (this.showBlock) {
                    if (this.showDropDown) {
                        this.showDropDown = booleanValue;
                    }
                    if (this.showTableActionsDropdown) {
                        this.showTableActionsDropdown = booleanValue;
                    }
                } else {
                    if (!this.showDropDown) {
                        this.showDropDown = booleanValue;
                    }
                    if (!this.showTableActionsDropdown) {
                        this.showTableActionsDropdown = booleanValue;
                    }
                    this.showBlock = true;
                }
            }
        });
    },
    watch: {
        visibility(newValue) {
            if (newValue == false) {
                this.initialize();
            }
        },
        tableRowData: {
            handler(newVal, oldVal) {
                this.tableRow = this.tableRowData;
                this.initialize();
                console.log('this.tableRowData[0]', this.tableRowData[0]);
            },
            deep: true
        }
    },
    methods:{
        sort(tableHead: any) {
            this.className = tableHead;
            if (tableHead === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = tableHead;
            this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
        },
      
        headerAlignment(column:any){
            if(column=='type' ) {
                return 'dropdown-width'
            } 
            else if(column == 'courseTargetTypeCodeId') {
                return 'w-40'
            }
             else if(column == 'userCount' ){
                return 'w-36'
            } 
            else if(column == 'entityName' || column == 'courseTypeCodeId'){
                return 'w-44'
            } 
            else if(column == 'courseExpiry' ){
                return 'w-36'
            }
            else if(column == 'dueDays' ){
                return 'w-36'
            } 
             else if(column == 'deleteIcon' ){
                return 'w-20'
            }  


            // else if(column == 'courseTargetTypeCodeId') {
            //     return 'dropdown-width'
            // }
            // else if(column == 'courseTypeCodeId') {
            //     return 'dropdown-width'

            // }
            // else if(column == 'userCount') {
            //     return 'dropdown-width'
            // }
            //  else if(column=='dueDays'){
            //     return 'dropdown-width'
            // }
            // else if(column=='courseExpiry'){
            //     return 'dropdown-width'
            // }
            // else if(column == 'deleteIcon') {
            //     return 'dropdown-width'
            // }
        },
       getFilterSize(columnName:any){
        if(columnName=='type') {
            return 'w-20'
        }
        else if(columnName=='courseTargetTypeCodeId'){
            return 'w-28'
        }
         else if(columnName=='courseTypeCodeId'){
            return 'w-28'
        }

       },
        isFilterNotNecessary(columnName:any)
        {
            if(columnName == 'deleteIcon' || columnName == 'dueDays' || columnName == 'courseExpiry' || columnName == 'userCount')
            {
                return true;
            } 
           
                return false;
        },
        paginationUpdations(currentPage: any, pageSize: any) {
            console.log('Pagination processing... ', currentPage, pageSize);
            this.currentPage = currentPage;
            this.pageSize = pageSize;
        },
        toolTip(column: any, e: any, index: any, row: any, status: any) {
            this.tipText = row[column];
            this.tipColumn = column;
            this.tipIndex = index;

            switch (status) {
                case 'text':
                    this.handleTextTooltip(e);
                    break;
                case 'circleButton':
                    if (e.offsetX < e.target.offsetWidth && e.offsetY < e.target.offsetHeight) {
                        this.pop = true;
                        this.styling.left = e.offsetX + e.target.offsetLeft + 24 + 'px';
                        this.styling.bottom = -18 + 'px';
                    }
            }
        },
          handleTextTooltip(e: any) {
            const container = e.currentTarget.closest('.table-ellipsis');
            if (container && this.isTextOverflowed(container)) {
                this.pop = true;
                this.styling.left = e.offsetX + 24 + 'px';
                this.styling.top = 48 + 'px';
            } else {
                this.pop = false;
            }
        },
        isTextOverflowed(element: any) {
            return element.scrollHeight > element.clientHeight;
        },
        actionOnClickingTableRow(rowData:any,index:any,column:any){
            
            console.log("dataaa12345678");
            console.log("rowData:",rowData);
            console.log("presentcolumn:",column);

            if(column=='deleteIcon'){
                    console.log("deleteicon getting",index,rowData);
                    emitter.emit('RowIndex',index);

            }
            else if (this.openPopup == true) {
                    let popupdata = {
                        entityData: rowData,
                        index: index
                    };
                    console.log("popupdata",popupdata);
                    emitter.emit('showData123', popupdata);
            }
            
        },
        doFalse(){
            this.pop=false;

                },
        initialize() {
            this.tableRow = this.tableRowData;
        },
        getcolumnLabel(columnName:any){
            console.log("ertgyhrfwsedfgt...",columnName);
            if(columnName =='deleteIcon') {
            return ''
            }
            
            const column=this.columns.find((col:{key:any})=>col.key==columnName);
            return column ? column.value : columnName;
        },
        isColumnArrayPresent(columnName: any) {
            // Returns true if the column name has corresponding dropdown options
            // console.log(
            //     'result key',
            //     this.dropdownOptions.some((option: { key: any }) => option.key === columnName)
            // );
            return this.dropdownOptions.some((option: { key: any }) => option.key === columnName);
        },
        getColumnRealtedValueFromArray(columnName: any, type: any) {
            console.log('sazxsadsad', columnName);
            console.log("typee:",type);
            
            const option = this.dropdownOptions.find((option: { key: any }) => option.key === type);
            // const entityData = this.dropdownOptions.find((option: { key: any }) => option.key === type);
            console.log("optiondata:",option);
            const option1 = option.value.find((soption:any) => {
                console.log("soption:",soption);
                
                
                return soption.value == columnName 
             });
            console.log('wasbdhjbdzjsad', option1);
             return option1 ? option1.label : [];
        },
         getDropdownOptionsForFilter(columnName: any) {
            console.log('filtervalue', columnName);
            
            let option= this.dropdownOptions.find((option: { key: any }) => option.key === columnName);
            console.log("valuee:",option);
            
            return option ? option.value : []
        
             
        },
    },
    computed:{
        sortedData: function (): any {
            return [...this.tableRow]
                .filter((row: any) => {
                //     let option=this.dropdownOptions.find((optionsData:any)=>{
                //         console.log("optionsDataaaa:",optionsData);
                        
                //         let data=optionsData.value.find((soption:any)=>{
                //             return row.type == soption.value;                  
                //         })
                //         console.log("dataagettinggg:",data ? data.label : []);
                        
                //         return data ? data.label : [];                           
                //     })
                //     console.log("optionnnnneeeeeee:",option.data);
                    
                    
                    for (const key in this.filters) {
                        console.log("filters:",this.filters);

                        
                        
                        const inputValue = this.filters[key];
                        if (inputValue !== '' && row[key]) {
                            if (Number.isInteger(row[key])) {
                                if (String(row[key]).toLowerCase().includes(String(inputValue)) === false) {
                                    return false;
                                }
                            } else {
                                if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                })
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    this.dynamicPagination = index + 1;
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        },
        columnNames: function (): any {
            const names = new Set();
            for (const key of this.headersData) {
                if (key != '_id') names.add(key);
            }
            return names;
        }
    },
})
</script>
<style scoped>
.dropdown-width{
    width:140px ;
}
.w-44{
    width: 176px !important;
}
/* .text-filters {
    width:170px;
}
.count-filters{
    width: 150px;
}
.delete-width {
    width : 80px;
} */
/* .inputtype-filter{
    width:180px;
} */
</style>